import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ItButtonModule } from '@iterra/app-lib/it-button';
import { ItIconModule } from '@iterra/app-lib/it-icons';

import { AuthGuestComponent } from '$components/auth-guest/auth-guest.component';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutIterraFooterComponent } from './layout-iterra-footer/layout-iterra-footer.component';
import { LayoutIterraNavbarComponent } from './layout-iterra-navbar/layout-iterra-navbar.component';
import { LayoutIterraComponent } from './layout-iterra/layout-iterra.component';

@NgModule({
  declarations: [
    LayoutBlankComponent,
    LayoutIterraComponent,
    LayoutIterraFooterComponent,
    LayoutIterraNavbarComponent,
  ],
  imports: [
    AuthGuestComponent,
    CommonModule,
    ItIconModule,
    ItButtonModule,
    MatSidenavModule,
    RouterModule,
  ],
})
export class LayoutModule {}
