import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  map,
  take,
  tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import {
  Widget,
  WidgetsFindParams,
} from '@iterra/app-lib/schemas';
import { convertKeysToCamel, convertKeysToSnake } from '@iterra/app-lib/utils';

import { ApiService } from './api.service';

const logger = new Logger('WidgetApi');

@Injectable({
  providedIn: 'root',
})
export class WidgetApi {
  private apiService = inject(ApiService);

  findWidgets(params: WidgetsFindParams): Observable<Widget[]> {
    return this.apiService.get<any>(
      '/widget',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(widgets => logger.debug('find widgets', widgets)),
      take(1),
    );
  }
}
