import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import { concatMap } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    first,
    map,
    skip,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

import { Authorization } from '../../schemas/auth.schemas';
import * as authActions from '../actions/auth.actions';
import * as connectionActions from '../actions/connection.actions';
import * as passwordActions from '../actions/password.actions';
import * as phoneActions from '../actions/phone.actions';
import * as routerActions from '../actions/router.actions';
import * as authSelectors from '../selectors/auth.selectors';
import * as connectionSelectors from '../selectors/connection.selectors';
import * as routerSelectors from '../selectors/router.selectors';

const logger = new Logger('RouterEffects');

@Injectable()
export class RouterEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  private router = inject(Router);

  goToRedirectUriOrUriEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(
        routerActions.goToRedirectUriAction,
      ),
      tap(() => logger.debug('goToRedirectUriOrUriEffect')),
      withLatestFrom(this.store$.pipe(
        select(routerSelectors.selectQueryParam('redirect_uri')),
      )),
      tap(([, redirectUri]) => logger.debug('redirectUri', redirectUri)),
      tap(([{commands, extras}, redirectUri]) => {
        !redirectUri && commands
          ? this.router.navigate(commands, extras)
          : this.router.navigateByUrl(redirectUri || '');
      }),
    ),
    {dispatch: false},
  );

  goWithCurrentRedirectUriEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(routerActions.goWithCurrentRedirectUriAction),
      switchMap(({commands, extras}) => this.store$.pipe(
        select(routerSelectors.selectQueryParam('redirect_uri')),
        map(redirectUri => {
          logger.debug('goWithCurrentRedirectUri:', commands, extras, redirectUri);

          if (!extras) {
            extras = {};
          }
          if (!extras.queryParams) {
            extras.queryParams = {};
          }
          extras.queryParams.redirect_uri = redirectUri;

          return {commands, extras};
        }),
        first(),
      )),
      tap(({commands, extras}) =>
        this.router.navigate(commands, extras)
          .catch(reason => logger.error('Navigate failed', reason)),
      ),
    ),
    {dispatch: false},
  );

  goConfirmSignUpCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendSignUpCodeSuccessAction),
      tap(() => {
        this.router.navigate(
          ['/auth/phone/sign-up/confirm'],
          {queryParams: {redirect_uri: '/auth/password'}},
        ).catch(reason => logger.error('Navigate failed', reason));
      }),
    ),
    {dispatch: false},
  );

  goConfirmSignInCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendSignInCodeSuccessAction),
      map(() => routerActions.goWithCurrentRedirectUriAction({
        commands: ['/auth/phone/sign-in/confirm'],
      })),
    ),
  );

  goConfirmPasswordResetCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendPasswordResetCodeSuccessAction),
      tap(() => {
        this.router.navigate(
          ['/auth/password/forgot/confirm'],
          {queryParams: {redirect_uri: '/auth/password/forgot/reset'}},
        ).catch(reason => logger.error('Navigate failed', reason));
      }),
    ),
    {dispatch: false},
  );

  goConnect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          passwordActions.passwordSuccessAction,
        ),
        tap(() => this.router.navigate(['/search'])),
      ),
    {dispatch: false},
  );

  goCurrentConnectionWidgetsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(connectionActions.connectSuccessAction),
      tap(({connection}) => logger.debug('goCurrentConnectionWidgetsEffect$ (connection)', connection)),
      tap(({connection}) => {
        this.router.navigate(
          ['c', connection.capsuleId, connection.locationId],
        ).catch(reason => logger.error('Navigate failed', reason));
      }),
    ),
    {dispatch: false},
  );

  goConnectOnLastDisconnectEffect$ = createEffect(
    () => this.store$.pipe(
      select(connectionSelectors.selectCurrentConnection),
      filter(currentConnection => !currentConnection),
      skip(1),
      tap(() => logger.debug('goConnectOnLastDisconnectEffect')),
      tap(() => {
        this.router.navigate(['/search'])
          .catch(reason => logger.error('Navigate failed', reason));
      }),
    ),
    {dispatch: false},
  );

  goFavoriteLocationListEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          routerActions.goFavoriteLocationListAction,
        ),
        tap(() => this.router.navigate(['/favorites/locations'])),
      ),
    {dispatch: false},
  );

  goAuthEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(
        routerActions.goAuthAction,
        authActions.signOutSuccessAction,
        authActions.signInByTelegramFailureAction,
      ),
      tap(() => this.router.navigateByUrl('/auth')),
    ),
    {dispatch: false},
  );

  redirectAfterAuthEffect$ = createEffect(
    () => this.store$.pipe(
      select(authSelectors.selectIsAuth),
      filter((isAuth: boolean) => !isAuth),
      concatMap(() => this.actions$.pipe(
        ofType(
          authActions.setInitAction,
          authActions.setAuthorizationAction,
        ),
        filter(({authorization}) => !!authorization),
        distinctUntilChanged((
            prev: { authorization: Authorization | null },
            curr: { authorization: Authorization | null },
          ) =>
            prev.authorization?.accessToken === curr.authorization?.accessToken,
        ),
        tap(({authorization}) => logger.debug('redirectAfterAuthEffect (authorization)', authorization)),
        withLatestFrom(
          this.store$.select(authSelectors.selectDeepLink),
        ),
        tap(([, deepLink]) => {
          const currentUri = [
            window.location.pathname,
            window.location.search,
            window.location.hash,
          ].join('');

          logger.debug('redirectAfterAuthEffect (deepLink)', deepLink);
          logger.debug('redirectAfterAuthEffect (currentUri)', currentUri);

          if (currentUri !== '/' && (!/^\/auth(\/|$)/.test(currentUri) || currentUri === '/auth/password')) {
            return;
          }

          if (deepLink !== null && (!/^\/auth(\/|$)/.test(deepLink) || deepLink === '/auth/password')) {
            return this.router.navigateByUrl(deepLink);
          }

          return this.router.navigateByUrl('/');
        }),
      )),
    ),
    {dispatch: false},
  );

  redirectIfNeedEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(
        phoneActions.validateCodeSuccessAction,
      ),
      withLatestFrom(this.store$.pipe(
        select(routerSelectors.selectQueryParam('redirect_uri')),
      )),
      filter(([, redirectUri]) => !!redirectUri),
      tap(([, redirectUri]) => logger.debug('redirectUri', redirectUri)),
      tap(([, redirectUri]) => this.router.navigateByUrl(redirectUri || '')),
    ),
    {dispatch: false},
  );

  goLoginEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(passwordActions.resetSuccessAction),
      tap(() => this.router.navigateByUrl('/auth/phone/sign-in')),
    ),
    {dispatch: false},
  );
}
