import { inject, Injectable } from '@angular/core';
import {
  select,
  Store,
} from '@ngrx/store';
import {
  Observable,
  of,
} from 'rxjs';
import {
  debounceTime,
  map,
  switchMap,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

import { Location } from '../schemas/location.schemas';
import * as locationActions from '../store/actions/location.actions';
import * as locationSelectors from '../store/selectors/location.selectors';

const logger = new Logger('LocationService');

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private store$ = inject(Store);

  fetchLocations(locationIds: number[]): Observable<Location[]> {

    return of(true).pipe(
      withLatestFrom(this.store$.pipe(
        select(locationSelectors.selectLocations, { locationIds }),
      )),
      map(([, locations]) => locations),
      tap(locations => logger.debug('fetchLocations (locations)', locations)),
      tap(locations => {
        const foundIds = locations.map((location: Location) => location.id);
        const notLoadedIds = locationIds.filter((id: number) => !foundIds.includes(id));

        if (notLoadedIds.length) {
          this.store$.dispatch(locationActions.loadLocationsAction({
            locationIds: notLoadedIds,
          }));
        }
      }),
      switchMap(() => {
        return this.store$.pipe(
          // debounceTime(50),
          select(locationSelectors.selectLocations, {locationIds}),
          tap(locations => logger.debug('fetchLocation2 (locations)', locations)),
          takeWhile(locations => locationIds.length > locations.length, true),
          // todo: Если пришли подгруженные локации, то нужно завершать поток
        );
      }),
      // filter(locations => !!locations.length),
    );
  }
}
