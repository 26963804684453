import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import {
    DEFAULT_PHONE_FEATURE_NAME,
    PhoneReducerState,
} from '../reducers/phone.reducer';


const selectAuthPhone = createFeatureSelector<PhoneReducerState>(DEFAULT_PHONE_FEATURE_NAME);

export const selectCode = createSelector(
    selectAuthPhone,
    state => state.code,
);

export const selectCodePhone = createSelector(
    selectCode,
    code => code?.phone,
);

export const selectCodeSentAt = createSelector(
    selectCode,
    code => code?.sentAt,
);

export const selectError = createSelector(
    selectAuthPhone,
    state => state.error,
);

export const selectIsLoading = createSelector(
    selectAuthPhone,
    state => state.isLoading,
);
