import {inject, Injectable} from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import {
  EMPTY,
  of,
} from 'rxjs';
import {
  catchError,
  first,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import { formatErrors } from '@iterra/app-lib/utils';

import { AuthPhoneApi } from '../../services/api/auth/auth-phone.api';
import * as phoneActions from '../actions/phone.actions';
import * as phoneSelectors from '../selectors/phone.selectors';

const log = new Logger('PhoneEffects');

@Injectable()
export class PhoneEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  private authPhoneApi = inject(AuthPhoneApi);

  sendCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendCodeAction),
      switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
        map(() => phoneActions.sendCodeSuccessAction({
          sentAt: Date.now(),
          phone,
        })),
        catchError(response => of(phoneActions.sendCodeFailureAction({
          error: formatErrors(response),
        }))),
        first(),
      )),
    ),
  );

  sendSignUpCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendSignUpCodeAction),
      switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
        map(() => phoneActions.sendSignUpCodeSuccessAction({
          sentAt: Date.now(),
          phone,
        })),
        catchError(response => of(phoneActions.sendSignUpCodeFailureAction({
          error: formatErrors(response),
        }))),
        first(),
      )),
    ),
  );

  sendSignInCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendSignInCodeAction),
      switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
        map(() => phoneActions.sendSignInCodeSuccessAction({
          sentAt: Date.now(),
          phone,
        })),
        catchError(response => of(phoneActions.sendSignInCodeFailureAction({
          error: formatErrors(response),
        }))),
        first(),
      )),
    ),
  );

  sendPasswordResetCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.sendPasswordResetCodeAction),
      switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
        map(() => phoneActions.sendPasswordResetCodeSuccessAction({
          sentAt: Date.now(),
          phone,
        })),
        catchError(response => of(phoneActions.sendPasswordResetCodeFailureAction({
          error: formatErrors(response),
        }))),
        first(),
      )),
    ),
  );

  validateCodeEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(phoneActions.validateCodeAction),
      withLatestFrom(this.store$.pipe(
        select(phoneSelectors.selectCode),
      )),
      switchMap(([{code}, phoneCode]) => {
        if (!phoneCode) {
          return EMPTY;
        }

        return this.authPhoneApi.validatePhoneCode({
          phone: phoneCode.phone,
          code,
        }).pipe(
          map(() => phoneActions.validateCodeSuccessAction({
            code: {
              ...phoneCode,
              value: code,
            },
          })),
          catchError(response => of(phoneActions.validateCodeFailureAction({
            error: formatErrors(response),
          }))),
        );
      }),
    ),
  );
}
