import { Injectable } from '@angular/core';

import { Logger } from '@iterra/app-lib/services';

export interface CallbackOptions {
  callback?: () => any;
  ctx?: any;
}

export interface CommonOptions extends CallbackOptions {
  params?: any;
  title?: any;
}

const logger = new Logger('YandexMetrikaService');

@Injectable({
  providedIn: 'root',
})
export class YandexMetrikaService {
  getCounterNameById(id: string | number): any {
    return `yaCounter${id}`;
  }

  getCounterById(id: any): any {
    return window[this.getCounterNameById(id)];
  }

  onReachGoal(counter: number, type: string, options: CommonOptions = {}): void {
    try {
      const ya = this.getCounterById(counter);
      logger.debug('yandexMetrika', ya, counter);
      if (ya) {
        ya.reachGoal(type, options.params, options.callback, options.ctx);
        logger.debug('reachGoal', type);
      }
    } catch (error) {
      logger.debug('reachGoal error', error);
      logger.debug(`'Event with type [${type}] can\'t be fired because counter is still loading'`);
    }
  }
}
