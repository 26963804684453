import {inject, Injectable} from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import { FavoriteLocation } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';

import { Location } from '../../schemas/location.schemas';
import { LocationApi } from '../../services/api/location.api';
import * as authActions from '../actions/auth.actions';
import * as favoriteActions from '../actions/favorite.actions';
import * as locationActions from '../actions/location.actions';

const logger = new Logger('FavoriteEffects');

@Injectable()
export class FavoriteEffects {
  private actions$ = inject(Actions);
  private locationApi = inject(LocationApi);
  private store$ = inject(Store);

  syncFavoriteLocationsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(
        authActions.setAuthorizationAction,
        favoriteActions.syncAction,
      ),
      switchMap(() => this.locationApi.fetchFavoriteLocations({limit: 0}).pipe(
        map(favoriteLocations => {
          logger.debug('Sync favoriteLocations', favoriteLocations);
          return favoriteActions.restoreAction({
            locations: favoriteLocations,
            folders: [],
          });
        }),
      )),
    ),
  );

  toggleFavoriteLocationEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(favoriteActions.toggleFavoriteLocationAction),
      tap(locationId => logger.debug('toggleLocationFavoriteEffect (locationId)', locationId)),
      switchMap(({locationId}) => {
          const params: FavoriteLocation = {
            locationId,
            folderId: 0,
          };
          return this.locationApi.toggleFavoriteLocation(params).pipe(
            map(() => this.store$.dispatch(favoriteActions.syncAction())),
          );
        },
      ),
    ),
    { dispatch: false },
  );


  loadFavoriteLocationsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(locationActions.loadLocationsSuccessAction),
      tap(({locations}) => logger.debug('loadFavoriteLocationsEffect (locations)', locations)),
      switchMap(({locations}) => {
        return this.locationApi.fetchFavoriteLocations({limit: 0}).pipe(
          tap(favoriteLocations => logger.debug('loadFavoriteLocationsEffect (locations)', favoriteLocations)),
          map(favoriteLocations => {
            const updatedLocations: Location[] = [];

            for (const location of locations) {
              const index = favoriteLocations.findIndex(item => item.locationId === location.id);
              updatedLocations.push({
                ...location,
                isFavorite: index > -1,
              });
            }

            return locationActions.upsertLocations({
              locations: updatedLocations,
            });
          }),
        );
      }),
    ),
  );

  // createFolderEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.createFolderAction),
  //         tap((locations) => log.debug('createFolderEffect (locations)', locations)),
  //         switchMap((locations) => {
  //             return this.locationApi.addFavoriteLocationFolder(locations.locationLeft.id, locations.locationRight.id).pipe(
  //                 tap(data => log.debug('addFavoriteLocationFolder (locations)', data)),
  //                 tap((data) => {
  //                     const folderId = data.folderId;
  //
  //                     if (folderId && folderId > 0) {
  //                         this.store$.dispatch(favoriteActions.createFolderSuccessAction({
  //                             locations: data.locations,
  //                             folderId,
  //                         }));
  //
  //                         this.router.navigateByUrl('capsule/folder/' + folderId)
  //                             .catch(reason => log.error('Navigate failed', reason));
  //                     }
  //                 }),
  //                 catchError(response => {
  //                     log.debug('loadLocationsEffect (response)', response);
  //                     this.store$.dispatch(favoriteActions.createFolderFailureAction({
  //                         error: utils.formatErrors(response),
  //                     }));
  //                     return of(false);
  //                 }),
  //             );
  //         }),
  //     ),
  //     {dispatch: false},
  // );

  // createFolderSuccessEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.createFolderSuccessAction),
  //         tap((data) => log.debug('createFolderSuccessEffect (data)', data)),
  //         map(() => this.store$.dispatch(favoriteActions.syncAction())),
  //     ),
  //     {dispatch: false},
  // );

  // createFolderFailEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.createFolderFailureAction),
  //         tap((error) => log.debug('createFolderFailEffect (error)', error)),
  //     ),
  //     {dispatch: false},
  // );

  // updateFolderTitleEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.updateFolderTitleAction),
  //         tap((data) => log.debug('updateFolderTitleEffect (data)', data)),
  //         switchMap((data) => {
  //             return this.locationApi.updateFolderTitle(data.folderId, data.newValue).pipe(
  //                 map(() => this.store$.dispatch(favoriteActions.syncAction())),
  //             );
  //         }),
  //     ),
  //     {dispatch: false},
  // );

  // ungroupLocationsEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.ungroupLocationsAction),
  //         tap((folderId) => log.debug('ungroupLocationsEffect (folderId)', folderId)),
  //         switchMap(({folderId}) => {
  //             return this.locationApi.ungroupFavoriteLocations(folderId).pipe(
  //                 switchMap(() => {
  //                     return [
  //                         favoriteActions.syncAction(),
  //                         routerActions.goFavoriteLocationListAction(),
  //                     ];
  //                 }),
  //             );
  //         }),
  //     ),
  // );

  // deleteFavoriteLocationFromFolderEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.deleteFavoriteLocationFromFolderAction),
  //         tap((locationId) => log.debug('deleteFavoriteLocationFromFolderEffect(locationId)', locationId)),
  //         switchMap(({locationId}) => {
  //             return this.locationApi.deleteFavoriteLocationFromFolder(locationId).pipe(
  //                 switchMap((result) => {
  //                     if (!result) {
  //                         return [
  //                             favoriteActions.syncAction(),
  //                             routerActions.goFavoriteLocationListAction(),
  //                         ];
  //                     } else {
  //                         return [favoriteActions.syncAction()];
  //                     }
  //                 }),
  //             );
  //         }),
  //     ),
  // );

  // addFavoriteLocationInFolderEffect$ = createEffect(
  //     () => this.actions$.pipe(
  //         ofType(favoriteActions.addFavoriteLocationInFolderAction),
  //         tap((data) => log.debug('addFavoriteLocationInFolderEffect(folderId, locationId)', data.folderId, data.locationId)),
  //         switchMap((data) => {
  //             return this.locationApi.addFavoriteLocationInFolder(data.folderId, data.locationId).pipe(
  //                 map(() => this.store$.dispatch(favoriteActions.syncAction())),
  //             );
  //         }),
  //     ),
  //     {dispatch: false},
  // );

}

