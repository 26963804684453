import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@iterra/app-lib/services';
import { Observable } from 'rxjs';
import {
    filter,
    mergeMap,
    take,
} from 'rxjs/operators';
import * as connectionSelectors from '../store/selectors/connection.selectors';


const logger = new Logger('AuthInterceptor');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private store$: Store,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private jwtHelperService: JwtHelperService,
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const currentUrl = this.router.routerState.snapshot.url;
        const urlSegments = currentUrl.split('/').filter(x => x);
        const widgetId = (urlSegments.length >= 4 && urlSegments[0] === 'c')
            ? urlSegments[3]
            : undefined;

        return this.store$.pipe(
            select(connectionSelectors.selectActiveAccessToken, { widgetId }),
            filter(accessToken => {
                if (!accessToken || request.url.includes('authorize/refresh')) {
                    return true;
                }
                const token = this.jwtHelperService.decodeToken(accessToken);
                const expDate = new Date(token.exp * 1000);
                const expTime = expDate.getTime() - Date.now() - 60 * 1000;
                if (expTime <= 0) {
                    logger.debug('Token expired', token);
                }
                return expTime > 0;
            }),
            take(1),
            mergeMap(accessToken => {
                const isNotAuth = request.url.includes('exists')
                    || request.url.includes('password')
                    || !request.url.includes('authentication');
                const authRequest = accessToken && isNotAuth
                    ? request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    })
                    : request;

                return next.handle(authRequest);
            }),
        );
    }
}

export const AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
};
