// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
// @ts-ignore
import { env } from 'environments/.env';


export const environment = {
    production: false,
    version: env.npm_package_version + '-dev',
    api: {
        url: 'https://dev.api.iterra.world/v1',
        iterraUrl: 'https://dev.iterra.world/',
    },
    widget: {
        url: 'https://dev.widget.iterra.world',
    },
    defaultLanguage: 'ru-RU',
    supportedLanguages: ['ru-RU', 'en-US'],
    mapboxKey: 'pk.eyJ1IjoiaXRlcnJhd29ybGQiLCJhIjoiY2t0dGdqdnV2MWRrajJvbnFtM3hkbWhnNSJ9.o3q8jDeCm0dZTtw_wnV8Tg',
    mapboxStyle: 'mapbox://styles/iterraworld/cktti8f5s03cb17nvjtwhb8lf',
    app: 'https://dev.app.iterra.world/',
    yandexCounterId: 89146328,
    urlLinkToolEndpoint: 'https://api.iterra.world/v1/url-data',
    uploadMediaUrl: 'https://dev.iterra.world/media/public',
};

export const serviceToken = '3b10e6776584ba3e19b0b015ce4118769ca3c4c5';
export const widgetApiUrl = 'https://dev.widget.iterra.world/';
export const iterraUrl = 'https://dev.iterra.world/';
export const rocketChatDefaultChannel = 'https://dev.chat.iterra.world/channel/iterra-dev/?layout=embedded';
export const app = 'https://dev.app.iterra.world/';
export const telegramBotUrl = 'https://t.me/iterra_user_dev_bot';
export const ALIAS_WIDGET_MAP: { [alias: string]: { id: number; module?: string; } } = {
    'about': {id: 1},
    'about-admin': {id: 2},
    'about-guest': {id: 15},
    'location-feed': {id: 3},
    'location-feed-admin': {id: 4},
    'location-feed-guest': {id: 16},
    'music-library': {id: 5},
    'music-library-admin': {id: 6},
    'music-library-guest': {id: 21},
    'abonnement': {id: 7},
    'abonnement-admin': {id: 8},
    'chat': {id: 9},
    'chat-admin': {id: 10},
    'location-map': {id: 11},
    'location-map-admin': {id: 12},
    'sale': {id: 13},
    'sale-admin': {id: 14},
    'sale-guest': {id: 17},
    'routes': {id: 18},
    'requests': {id: 19},
    'location-settings': {id: 20},
    'location-content': {id: 35},
    'location-content-admin': {id: 36},
    'location-content-guest': {id: 38},
    'location-content-2': {id: 37, module: 'location-content'},
    'location-content-2-guest': {id: 39, module: 'location-content-guest'},
    'location-content-3': {id: 47, module: 'location-content'},
    'location-content-3-guest': {id: 46, module: 'location-content-guest'},
    'location-content-4': {id: 49, module: 'location-content'},
    'location-content-4-guest': {id: 48, module: 'location-content-guest'},
    'location-content-5': {id: 51, module: 'location-content'},
    'location-content-5-guest': {id: 50, module: 'location-content-guest'},
    'cultural-driver': {id: 43},
    'cultural-driver-admin': {id: 44},
    'media-content-user': {id: 42, module: 'location-content'},
    'media-content-guest': {id: 45, module: 'location-content-guest'},
    'telegram': {id: 52},
    'telegram-guest': {id: 53},
    'vk': {id: 54},
    'vk-guest': {id: 55},
    'telegram-comments': {id: 56},
    'telegram-comments-guest': {id: 57},
    'tests': {id: 72},
    'tests-admin': {id: 73},
    'tests-guest': {id: 74, module: 'tests'},
    'posting': {id: 63},
    'posting-guest': {id: 64},
    'posting-admin': {id: 65},
    'anekdot': {id: 77},
    'emoji': {id: 76},
    'emoji-admin': {id: 75},
    'content-admin': {id: 78},
    'vk-content-admin': {id: 79, module: 'content-admin'},
    'telegram-content-admin': {id: 80, module: 'content-admin'},
    'site-content-admin': {id: 81, module: 'content-admin'},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
