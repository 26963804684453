import {
    createReducer,
    on,
} from '@ngrx/store';

import { DemoCode, ResponseError } from '@iterra/app-lib/schemas';

import * as scannerActions from '../actions/scanner.actions';

export const DEFAULT_SCANNER_FEATURE_NAME = 'scanner';

export interface ScannerReducerState {
    isLoading: boolean;
    demoCodes: DemoCode[];
    error: ResponseError | null;
}

const initialState: ScannerReducerState = {
    isLoading: false,
    demoCodes: [],
    error: null,
};

export const scannerReducer = createReducer(
    initialState,
    on(
        scannerActions.loadDemoCodesAction,
        state => ({
            ...state,
            isLoading: true,
            error: null,
        }),
    ),
    on(
        scannerActions.loadDemoCodesSuccessAction,
        (state, {demoCodes}) => ({
            ...state,
            isLoading: false,
            demoCodes,
        }),
    ),
    on(
        scannerActions.loadDemoCodesFailureAction,
        (state, {error}) => ({
            ...state,
            isLoading: false,
            error,
        }),
    ),
);
