import { NgModule } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appActions from './actions/app.actions';


@NgModule({
    imports: [],
})
export class AppStoreModule {

    constructor(store$: Store) {
        store$.dispatch(appActions.initAction());
    }

}
