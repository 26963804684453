import {
    createAction,
    props,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { Capsule } from '../../schemas/capsule.schemas';

export const setCapsule = createAction(
    '[Capsule] Set Capsule',
    props<{ capsule: Capsule }>(),
);

export const setCapsules = createAction(
    '[Capsule] Set Capsules',
    props<{ capsules: Capsule[] }>(),
);

export const addCapsule = createAction(
    '[Capsule] Add Capsule',
    props<{ capsule: Capsule }>(),
);

export const addCapsules = createAction(
    '[Capsule] Add Capsules',
    props<{ capsules: Capsule[] }>(),
);

export const upsertCapsule = createAction(
    '[Capsule] Upsert Capsule',
    props<{ capsule: Capsule }>(),
);

export const upsertCapsules = createAction(
    '[Capsule] Upsert Capsules',
    props<{ capsules: Capsule[] }>(),
);

export const loadCapsulesAction = createAction(
    '[Capsule] Load Capsules',
    props<{ capsuleIds?: (number | string)[] }>(),
);

export const loadCapsulesSuccessAction = createAction(
    '[Capsule] Load Capsules Success',
    props<{ capsules: Capsule[] }>(),
);

export const loadCapsulesFailureAction = createAction(
    '[Capsule] Load Capsules Failure',
    props<{ error: ResponseError }>(),
);



