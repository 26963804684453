import { Injectable } from '@angular/core';

import { Logger } from '@iterra/app-lib/services';
import { convertKeysToCamel, convertKeysToSnake } from '@iterra/app-lib/utils';

import { Observable } from 'rxjs';
import {
  first,
  map,
  tap,
} from 'rxjs/operators';
import {
  Authorization,
  TelegramCredentials,
  TgUserExists,
} from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('AuthApi');

@Injectable({
  providedIn: 'root',
})
export class AuthApi {

  constructor(
    private apiService: ApiService,
  ) { }

  refresh(refreshToken: string): Observable<Authorization> {
    return this.apiService.post<any>(
      '/authorization/authorize/refresh',
      convertKeysToSnake({refreshToken}),
    ).pipe(
      map(convertKeysToCamel),
      first(),
    );
  }

  authorizeByTelegram(credentials: TelegramCredentials): Observable<Authorization> {
    return this.apiService.post<any>(
      '/authentication/telegram/authorize',
      convertKeysToSnake(credentials),
      [401],
    ).pipe(
      map(convertKeysToCamel),
      tap(authorization => logger.debug('authorizeByTelegram (authorization)', authorization)),
      first(),
    );
  }

  checkExistsTgUser(userId?: number): Observable<boolean> {
    const params: { userId?: number } = {};

    if (userId) {
      params.userId = userId;
    }

    return this.apiService.get<TgUserExists>(
      '/authentication/telegram/exists',
      params,
    ).pipe(
      map(convertKeysToCamel),
      tap((response: TgUserExists) => logger.debug('checkExistsTgUser (exists)', response.exists)),
      map((response: TgUserExists) => response.exists),
      first(),
    );
  }

}
