import { Authorization } from '../../schemas/auth.schemas';


export function toAuthorization(source: any): Authorization {
    return {
        accessToken: source.access_token,
        refreshToken: source.refresh_token,
        tokenType: source.token_type,
        expiresIn: source.expires_in,
    };
}
