import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  createReducer,
  on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { Capsule } from '../../schemas/capsule.schemas';
import * as capsuleActions from '../actions/capsule.actions';

export const DEFAULT_CAPSULE_FEATURE_NAME = 'capsules';

export interface CapsuleReducerState extends EntityState<Capsule> {
  error: ResponseError | null;
}

export function selectCapsuleId(a: Capsule): number {
  return a.id;
}

export function sortByTitle(a: Capsule, b: Capsule): number {
  return a.title.localeCompare(b.title);
}

export const capsuleAdapter: EntityAdapter<Capsule> = createEntityAdapter<Capsule>({
  selectId: selectCapsuleId,
  sortComparer: sortByTitle,
});

export const initialState: CapsuleReducerState = capsuleAdapter.getInitialState({
  error: null,
});

export const capsuleReducer = createReducer(
  initialState,
  on(
    capsuleActions.addCapsule,
    (state, {capsule}) => capsuleAdapter.addOne(capsule, state),
  ),
  on(
    capsuleActions.addCapsules,
    (state, {capsules}) => capsuleAdapter.addMany(capsules, state),
  ),
  on(
    capsuleActions.setCapsule,
    (state, {capsule}) => capsuleAdapter.setOne(capsule, state),
  ),
  on(
    capsuleActions.setCapsules,
    (state, {capsules}) => capsuleAdapter.setAll(capsules, state),
  ),
  on(
    capsuleActions.upsertCapsule,
    (state, {capsule}) => capsuleAdapter.upsertOne(capsule, state),
  ),
  on(
    capsuleActions.upsertCapsules,
    (state, {capsules}) => capsuleAdapter.upsertMany(capsules, state),
  ),
);
