import {
  createAction,
  props,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { Authorization } from '../../schemas/auth.schemas';

export const resetAction = createAction(
  '[Password] Reset',
  props<{ password: string }>(),
);

export const resetSuccessAction = createAction(
  '[Password] Reset Success',
);

export const resetFailureAction = createAction(
  '[Password] Reset Failure',
  props<{ error: ResponseError; }>(),
);

export const passwordAction = createAction(
  '[Password] Password',
  props<{ password: string }>(),
);

export const passwordSuccessAction = createAction(
  '[Password] Password Success',
);

export const passwordFailureAction = createAction(
  '[Password] Password Failure',
  props<{ error: ResponseError; }>(),
);
