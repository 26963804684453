import {inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {
  map,
  take,
  tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import {
  Capsule,
  CapsuleFindParams,
  LocationWidget,
  LocationWidgetFindParams,
  Widget,
} from '@iterra/app-lib/schemas';
import { convertKeysToCamel, convertKeysToSnake } from '@iterra/app-lib/utils';

import { ApiService } from './api.service';

const logger = new Logger('CapsuleApi');

@Injectable({
  providedIn: 'root',
})
export class CapsuleApi {
  private apiService = inject(ApiService);

  fetchWidgets(locationId: number): Observable<Widget[]> {
    return this.apiService.get<any>(
      '/capsule/widget',
      convertKeysToSnake({ locationId, limit: 0 }),
    ).pipe(
      map(convertKeysToCamel),
      tap(widgets => logger.debug('fetchWidgets (widgets)', widgets)),
      take(1),
    );
  }

  fetchLocationWidgets(
    params: LocationWidgetFindParams,
  ): Observable<LocationWidget[]> {
    return this.apiService.get<any>(
      '/capsule/widget-config',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(items => logger.debug('fetchLocationWidgets (items)', items)),
      take(1),
    );
  }

  fetchCapsule(params: CapsuleFindParams): Observable<Capsule> {
    return this.apiService.get<any>(
      'capsule/' + params.id,
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(capsule => logger.debug('fetchCapsule (capsule)', capsule)),
      take(1),
    );
  }

  fetchCapsules(params: CapsuleFindParams): Observable<Capsule[]> {
    return this.apiService.get<any>(
      '/capsule',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(capsules => logger.debug('fetchCapsules (capsules)', capsules)),
      take(1),
    );
  }
}
