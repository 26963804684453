import { Dictionary } from '@ngrx/entity/src/models';
import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { WorkTable } from '../../schemas/worktable.schemas';
import {
    DEFAULT_WORK_TABLE_FEATURE_NAME,
    workTableAdapter,
    WorkTableReducerState,
} from '../reducers/work-table.reducer';


const selectWorkTableState = createFeatureSelector<WorkTableReducerState>(DEFAULT_WORK_TABLE_FEATURE_NAME);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = workTableAdapter.getSelectors(selectWorkTableState);

// select the array of work table ids
export const selectWorkTableIds = selectIds;

// select the dictionary of work table entities
export const selectWorkTableEntities = selectEntities;

// select the array of work tables
export const selectAllWorkTables = selectAll;

// select the total work table count
export const selectWorkTableTotal = selectTotal;

export const selectIsLoading = createSelector(
    selectWorkTableState,
    state => state.isLoading,
);

export const selectWorkTable = createSelector(
    selectWorkTableEntities,
    (entities: Dictionary<WorkTable>, props: { id: number }) =>
        entities[props.id] || null,
);

export const selectWorkTables = createSelector(
    selectWorkTableEntities,
    (entities: Dictionary<WorkTable>, props: { ids: number[] }) => {
        const workTables = [];

        for (const id of props.ids) {
            const workTable = entities[id];
            if (workTable) {
                workTables.push(workTable);
            }
        }

        return workTables;
    },
);

export const selectCurrentWorkTableId = createSelector(
    selectWorkTableState,
    state => state.currentWorkTableId,
);

export const selectCurrentWorkTable = createSelector(
    selectWorkTableEntities,
    selectCurrentWorkTableId,
    (entities: Dictionary<WorkTable>, id: number | null) =>
        id ? (entities[id] || null) : null,
);
