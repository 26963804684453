import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';
import {
    createReducer,
    on,
} from '@ngrx/store';

import {
  LocationGeo,
  LocationTag,
  ResponseError,
  Tag,
  WorkTable,
  WorkTableWidget,
} from '@iterra/app-lib/schemas';

import { Location } from '../../schemas/location.schemas';
import * as locationActions from '../actions/location.actions';

export const DEFAULT_LOCATION_FEATURE_NAME = 'locations';

export interface LocationReducerState extends EntityState<Location> {
    error: ResponseError | null;
    locationTags: EntityState<LocationTag>;
    tags: EntityState<Tag>;
    locationGeos: EntityState<LocationGeo>;
    workTables: EntityState<WorkTable>;
    workTableWidgets: EntityState<WorkTableWidget>;
}

export function selectLocationId(a: Location): number {
    return a.id;
}

export function sortByTitle(a: Location, b: Location): number {
    return a.title.localeCompare(b.title);
}

export const locationAdapter: EntityAdapter<Location> = createEntityAdapter<Location>({
    selectId: selectLocationId,
    sortComparer: sortByTitle,
});

export const locationTagAdapter: EntityAdapter<LocationTag> = createEntityAdapter<LocationTag>();
export const tagAdapter: EntityAdapter<Tag> = createEntityAdapter<Tag>();
export const locationGeoAdapter: EntityAdapter<LocationGeo> = createEntityAdapter<LocationGeo>();
export const workTableAdapter: EntityAdapter<WorkTable> = createEntityAdapter<WorkTable>();
export const workTableWidgetAdapter: EntityAdapter<WorkTableWidget> = createEntityAdapter<WorkTableWidget>();

export const initialState: LocationReducerState = locationAdapter.getInitialState({
    error: null,
    locationTags: locationTagAdapter.getInitialState(),
    tags: tagAdapter.getInitialState(),
    locationGeos: locationGeoAdapter.getInitialState(),
    workTables: workTableAdapter.getInitialState(),
    workTableWidgets: workTableWidgetAdapter.getInitialState(),
});

export const locationReducer = createReducer(
    initialState,
    on(
        locationActions.addLocation,
        (state, {location}) => locationAdapter.addOne(location, state),
    ),
    on(
        locationActions.setLocation,
        (state, {location}) => locationAdapter.setOne(location, state),
    ),
    on(
        locationActions.upsertLocation,
        (state, {location}) => locationAdapter.upsertOne(location, state),
    ),
    on(
        locationActions.addLocations,
        (state, {locations}) => locationAdapter.addMany(locations, state),
    ),
    on(
        locationActions.upsertLocations,
        (state, {locations}) => locationAdapter.upsertMany(locations, state),
    ),
    on(
        locationActions.updateLocation,
        (state, {update}) => locationAdapter.updateOne(update, state),
    ),
    on(
        locationActions.updateLocations,
        (state, {updates}) => locationAdapter.updateMany(updates, state),
    ),
    on(
        locationActions.mapLocation,
        (state, {entityMap}) => locationAdapter.mapOne(entityMap, state),
    ),
    on(
        locationActions.mapLocations,
        (state, {entityMap}) => locationAdapter.map(entityMap, state),
    ),
    on(
        locationActions.deleteLocation,
        (state, {id}) => locationAdapter.removeOne(id, state),
    ),
    on(
        locationActions.deleteLocations,
        (state, {ids}) => locationAdapter.removeMany(ids, state),
    ),
    on(
        locationActions.deleteLocationsByPredicate,
        (state, {predicate}) => locationAdapter.removeMany(predicate, state),
    ),
    on(
        locationActions.setLocations,
        (state, {locations}) => locationAdapter.setAll(locations, state),
    ),
    on(
        locationActions.clearLocations,
        state => locationAdapter.removeAll(state),
    ),
    on(
        locationActions.loadLocationsFailureAction,
        locationActions.loadLocationsTagsFailureAction,
        locationActions.loadTagsFailureAction,
        locationActions.loadLocationsGeosFailureAction,
        locationActions.loadWorkTablesFailureAction,
        locationActions.loadWorkTableWidgetsFailureAction,
        (state, {error}) => ({
            ...state,
            error,
        }),
    ),
    on(
        locationActions.loadLocationsTagsSuccessAction,
        (state, {locationTags}) => ({
            ...state,
            locationTags: locationTagAdapter.upsertMany(locationTags, state.locationTags),
        }),
    ),
    on(
        locationActions.loadTagsSuccessAction,
        (state, {tags}) => ({
            ...state,
            tags: tagAdapter.upsertMany(tags, state.tags),
        }),
    ),
    on(
        locationActions.loadLocationsGeosSuccessAction,
        (state, {locationGeos}) => ({
            ...state,
            locationGeos: locationGeoAdapter.upsertMany(locationGeos, state.locationGeos),
        }),
    ),
    on(
        locationActions.loadWorkTablesSuccessAction,
        (state, {workTables}) => ({
            ...state,
            workTables: workTableAdapter.upsertMany(workTables, state.workTables),
        }),
    ),
    on(
        locationActions.loadWorkTableWidgetsSuccessAction,
        (state, {workTableWidgets}) => ({
            ...state,
            workTableWidgets: workTableWidgetAdapter.upsertMany(workTableWidgets, state.workTableWidgets),
        }),
    ),
);
