import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    EMPTY,
    of,
} from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    tap,
} from 'rxjs/operators';

import { Location, LocationGeo, LocationTag, Tag, WorkTable, WorkTableWidget } from '@iterra/app-lib/schemas';
import { LocationApi, LocationTagApi, Logger, WorkTableApi} from '@iterra/app-lib/services';
import { formatErrors } from '@iterra/app-lib/utils';

import { LocationApi as LocationNewApi } from '../../services/api/location.api';
import * as locationActions from '../actions/location.actions';

const logger = new Logger('LocationEffects');

@Injectable()
export class LocationEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private locationApi: LocationApi,
        private locationNewApi: LocationNewApi,
        private locationTagApi: LocationTagApi,
        private workTableApi: WorkTableApi,
    ) {}

    loadLocationsSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsSuccessAction),
            tap(({locations}) => logger.debug('loadLocationsSuccessEffect (locations)', locations)),
            map(({locations}) => locationActions.upsertLocations({locations})),
        ),
    );

    // loadLocationsPointsEffect$ = createEffect(
    //     () => this.actions$.pipe(
    //         ofType(locationActions.loadLocationsSuccessAction),
    //         tap(({locations}) => logger.debug('loadLocationsPointsEffect (locations)', locations)),
    //         concatMap(({locations}) => {
    //             const locationIds = locations.map(location => location.id);
    //
    //             return this.locationApi.fetchPointsByLocationIds(locationIds).pipe(
    //                 tap(points => logger.debug('loadLocationsPointsEffect (points)', points)),
    //                 map(points => {
    //                     const updatedLocations: Location[] = [];
    //
    //                     for (const location of locations) {
    //                         for (const point of points) {
    //                             if (location.id === point.locationId) {
    //                                 updatedLocations.push({
    //                                     ...location,
    //                                     isDynamic: point.isDynamic,
    //                                 });
    //                                 break;
    //                             }
    //                         }
    //                     }
    //
    //                     return locationActions.upsertLocations({
    //                         locations: updatedLocations,
    //                     });
    //                 }),
    //                 first(),
    //             );
    //         }),
    //     ),
    // );

    loadRootLocationTreeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadRootLocationTreeAction),
            tap(({rootLocationId}) => logger.debug('loadRootLocationTreeEffect (rootLocationId)', rootLocationId)),
            filter(({rootLocationId}) => rootLocationId > 0),
            concatMap(({rootLocationId}) => {
                return this.locationNewApi.fetchRootLocationTree({locationId: rootLocationId, limit: 0}).pipe(
                    tap(locations => logger.debug('loadLocationsEffect (locations)', locations)),
                    tap((locations: Location[]) => {
                        if (locations.length) {
                            this.store$.dispatch(locationActions.loadLocationsSuccessAction({
                                locations: locations.map(location => ({
                                    ...location,
                                    level: location.path.split(',').length,
                                })),
                            }));
                        }
                    }),
                    catchError(response => {
                        logger.debug('loadLocationsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsFailureAction({
                            error: formatErrors(response),
                        }));
                        return of(false);
                    }),
                );
            }),
        ),
        {dispatch: false},
    );


    loadLocationsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsAction),
            tap(({locationIds}) => logger.debug('loadLocationsEffect (locationIds)', locationIds)),
            filter(({locationIds}) => !!locationIds?.length),
            concatMap((params: { locationIds?: number[] }) => {
                return this.locationNewApi.findLocations({
                    idIn: params.locationIds?.join(','),
                }).pipe(
                    tap(locations => logger.debug('loadLocationsEffect (locations)', locations)),
                    tap((locations: Location[]) => {
                        if (locations.length) {
                            this.store$.dispatch(locationActions.loadLocationsSuccessAction({
                                locations: locations.map(location => ({
                                    ...location,
                                    level: location.path.split(',').length,
                                })),
                            }));
                        }
                    }),
                    catchError(response => {
                        logger.debug('loadLocationsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsFailureAction({
                            error: formatErrors(response),
                        }));
                        return of(false);
                    }),
                );
            }),
        ),
        {dispatch: false},
    );

    loadLocationsTagsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsTagsAction),
            filter(({locationIds}) => !!locationIds?.length),
            tap(({locationIds}) => logger.debug('loadLocationsTagsEffect (locationIds)', locationIds)),
            concatMap(({locationIds}) => {
                return this.locationTagApi.fetchTagsByLocationIds(locationIds).pipe(
                    tap(locationTags => logger.debug('loadLocationsTagsEffect (locationTags)', locationTags)),
                    map((locationTags: LocationTag[]) =>
                        locationActions.loadLocationsTagsSuccessAction({
                            locationTags,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadLocationsTagsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsTagsFailureAction({
                            error: formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadTagsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadTagsAction),
            filter(({tagIds}) => !!tagIds?.length),
            tap(({tagIds}) => logger.debug('loadTagsEffect (tagIds)', tagIds)),
            concatMap(({tagIds}) => {
                return this.locationTagApi.fetchTags(tagIds).pipe(
                    tap(tags => logger.debug('loadTagsEffect (tags)', tags)),
                    map((tags: Tag[]) =>
                        locationActions.loadTagsSuccessAction({
                            tags,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadTagsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadTagsFailureAction({
                            error: formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadLocationsGeosEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsGeosAction),
            filter(({locationIds}) => !!locationIds?.length),
            tap(({locationIds}) => logger.debug('loadLocationsGeosEffect (locationIds)', locationIds)),
            concatMap(({locationIds}) => {
                return this.locationApi.fetchGeoByLocationIds(locationIds).pipe(
                    tap(locationGeos => logger.debug('loadLocationsGeosEffect (locationGeos)', locationGeos)),
                    map((locationGeos: LocationGeo[]) =>
                        locationActions.loadLocationsGeosSuccessAction({
                            locationGeos,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadLocationsGeosEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsGeosFailureAction({
                            error: formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadWorkTablesEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadWorkTablesAction),
            filter(({workTableIds}) => !!workTableIds?.length),
            tap(({workTableIds}) => logger.debug('loadWorkTablesEffect (workTableIds)', workTableIds)),
            concatMap(({workTableIds}) => {
                return this.workTableApi.findWorkTables({ids: workTableIds}).pipe(
                    tap(workTables => logger.debug('loadWorkTablesEffect (workTables)', workTables)),
                    map((workTables: WorkTable[]) =>
                        locationActions.loadWorkTablesSuccessAction({
                            workTables,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadWorkTablesEffect (response)', response);
                        this.store$.dispatch(locationActions.loadWorkTablesFailureAction({
                            error: formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadWorkTableWidgetsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadWorkTableWidgetsAction),
            filter(({workTableIds}) => !!workTableIds?.length),
            tap(({workTableIds}) => logger.debug('loadWorkTableWidgetsEffect (workTableIds)', workTableIds)),
            concatMap(({workTableIds}) => {
                logger.debug('loadWorkTableWidgetsEffect (RUN)', workTableIds);

                return this.workTableApi.findWorkTableWidgets({workTableIds}).pipe(
                    tap(workTableWidgets => logger.debug('loadWorkTableWidgetsEffect (workTableWidgets)', workTableWidgets)),
                    map((workTableWidgets: WorkTableWidget[]) =>
                        locationActions.loadWorkTableWidgetsSuccessAction({
                            workTableWidgets,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadWorkTableWidgetsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadWorkTableWidgetsFailureAction({
                            error: formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

}
