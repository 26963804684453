import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  map,
  take,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import { DemoCode } from '@iterra/app-lib/schemas';
import { convertKeysToCamel } from '@iterra/app-lib/utils';

const logger = new Logger('DemoService');

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  private http = inject(HttpClient);

  fetchDemoCodes(): Observable<DemoCode[]> {
    const endpoint = 'assets/json/demo-codes.json';

    return this.http.get<DemoCode[]>(endpoint).pipe(
      map(convertKeysToCamel),
      take(1),
    );
  }
}
