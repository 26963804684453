import {inject, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  select,
  Store,
} from '@ngrx/store';
import {
  EMPTY,
  of,
} from 'rxjs';
import {
  catchError,
  first,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import { formatErrors } from '@iterra/app-lib/utils';

import { AuthPasswordApi } from '../../services/api/auth/auth-password.api';
import * as passwordActions from '../actions/password.actions';
import * as phoneSelectors from '../selectors/phone.selectors';

const log = new Logger('PasswordEffects');

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class PasswordEffects {
  private actions$ = inject(Actions);
  private authPasswordApi = inject(AuthPasswordApi);
  private router = inject(Router);
  private store$ = inject(Store);

  // noinspection JSUnusedGlobalSymbols
  resetPasswordEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(passwordActions.resetAction),
      withLatestFrom(this.store$.pipe(
        select(phoneSelectors.selectCode),
      )),
      switchMap(([{password}, phoneCode]) => {
        if (!phoneCode?.value) {
          return EMPTY;
        }

        return this.authPasswordApi.reset({
          password,
          phone: phoneCode.phone,
          code: phoneCode.value,
        }).pipe(
          map(() => passwordActions.resetSuccessAction()),
          catchError(response => of(passwordActions.resetFailureAction({
            error: formatErrors(response),
          }))),
          first(),
        );
      }),
    ),
  );

  createPasswordEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(passwordActions.passwordAction),
      switchMap(({password}) => this.authPasswordApi.create(password).pipe(
        map(() => passwordActions.passwordSuccessAction()),
        catchError(response => of(passwordActions.passwordFailureAction({
          error: formatErrors(response),
        }))),
        first(),
      )),
    ),
  );

}
