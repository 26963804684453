import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
    PasswordChange,
    PasswordResetParams,
} from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';


@Injectable({
    providedIn: 'root',
})
export class AuthPasswordApi {
    private readonly prefix: string;

    constructor(
        private apiService: ApiService,
    ) {
        this.prefix = '/authentication/password';
    }

    private makeUri(path: string = ''): string {
        return `${this.prefix}${path}`;
    }

    create(password: string): Observable<null> {
        return this.apiService.post<null>(
            this.makeUri(),
            {password},
        );
    }

    update(passwordChange: PasswordChange): Observable<null> {
        return this.apiService.patch<null>(
            this.makeUri(),
            { // ToDo: Use camelCase to under_score object converter
                old_password: passwordChange.oldPassword,
                new_password: passwordChange.newPassword,
            },
        );
    }

    reset(params: PasswordResetParams): Observable<null> {
        return this.apiService.post<any>(
            this.makeUri('/reset'),
            params,
        ).pipe(first());
    }

}
