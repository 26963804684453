import {inject, Injectable} from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import { WorkTable, WorkTableWidget } from '@iterra/app-lib/schemas';
import { Logger, WorkTableApi } from '@iterra/app-lib/services';
import { formatErrors } from '@iterra/app-lib/utils';

import * as layoutActions from '../actions/layout.actions';
import * as widgetActions from '../actions/widget.actions';
import * as workTableActions from '../actions/work-table.actions';
import * as workTableSelectors from '../selectors/work-table.selectors';

const logger = new Logger('WorkTableEffects');

@Injectable()
export class WorkTableEffects {
  private actions$ = inject(Actions);
  private store$= inject(Store);
  private workTableApi= inject(WorkTableApi);

  loadWorkTableEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(workTableActions.loadWorkTableAction),
      tap(workTableId => logger.debug('loadWorkTableEffect (workTableId)', workTableId)),
      switchMap(({workTableId}) =>
        this.workTableApi.fetchWorkTable(workTableId).pipe(
          tap(workTable => logger.debug('loadWorkTableEffect (workTableId, workTable)', workTableId, workTable)),
          map((workTable: WorkTable) =>
            workTableActions.loadWorkTableSuccessAction({
              workTable,
            }),
          ),
          catchError(response => {
            logger.debug('loadWorkTableEffect (response)', response);
            return of(workTableActions.loadWorkTableFailureAction({
              error: formatErrors(response),
            }));
          }),
          first(),
        ),
      ),
    ),
  );

  loadWorkTableWidgetIdsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(workTableActions.loadWorkTableSuccessAction),
      tap(({workTable}) => logger.debug('loadWorkTableWidgetIdsEffect (workTable)', workTable)),
      switchMap(({workTable}) =>
        this.workTableApi.findWorkTableWidgets({workTableId: workTable.id}).pipe(
          tap(workTableWidgets => logger.debug('loadWorkTableWidgetIdsEffect (workTableWidgets)', workTableWidgets)),
          map((workTableWidgets: WorkTableWidget[]) =>
            workTableActions.upsertWorkTable({
              workTable: {
                ...workTable,
                widgetIds: workTableWidgets.map(workTableWidget => workTableWidget.widgetId),
              },
            }),
          ),
          catchError(response => {
            logger.debug('loadWorkTableWidgetIdsEffect (response)', response);
            return of(workTableActions.loadWorkTableFailureAction({
              error: formatErrors(response),
            }));
          }),
          first(),
        ),
      ),
    ),
  );

  loadCurrentWorkTableWidgetsEffect$ = createEffect(
    () => this.store$.pipe(
      select(workTableSelectors.selectCurrentWorkTable),
      filter(workTable => !!workTable?.widgetIds?.length),
      tap(workTable => logger.debug('loadCurrentWorkTableWidgetsEffect (workTable)', workTable)),
      map(workTable => widgetActions.loadWidgetsAction({
        ids: workTable?.widgetIds || [],
      })),
    ),
  );

  showCurrentWorkTableWidgetsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(widgetActions.loadWidgetsSuccessAction),
      switchMap(({widgets}) => this.store$.pipe(
        select(workTableSelectors.selectCurrentWorkTable),
        filter(workTable => !!workTable?.widgetIds?.length),
        tap(workTable => logger.debug('showCurrentWorkTableWidgetsEffect (workTable)', workTable)),
        map(workTable => widgets.filter(widget => (workTable?.widgetIds || []).includes(widget.id))),
        filter(currentWorkTableWidgets => !!currentWorkTableWidgets.length),
        map(() => workTableActions.loadCurrentWorkTableWidgetsSuccessAction()),
        first(),
      )),
    ),
  );

  upsertWorkTableStyleEffect$ = createEffect(
    () => this.store$.pipe(
      select(workTableSelectors.selectCurrentWorkTable),
      tap(workTable => logger.debug('upsertWorkTableStyleEffect (workTable)', workTable)),
      map(workTable => layoutActions.upsertWorkTableStyleAction(
        {
          workTableStyle: {
            title: workTable?.manifest.title,
            background: workTable?.manifest.bgImage,
          },
        },
      )),
    ),
  );
}
