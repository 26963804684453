import {inject, Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  Actions,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {
  map,
  take,
  tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

import { AuthFacade } from '../facades/auth.facade';
import { TelegramCredentials } from '../schemas/auth.schemas';
import * as authActions from '../store/actions/auth.actions';

const logger = new Logger('TelegramAutoAuthGuard');

@Injectable({
  providedIn: 'root',
})
export class TelegramAutoAuthGuard implements CanActivate, CanActivateChild {
  private router = inject(Router);
  private authFacade = inject(AuthFacade);
  private actions$ = inject(Actions);
  private store$ = inject(Store);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (childRoute.queryParamMap.get('auth') !== 'telegram') {
      return true;
    }
    logger.debug('canActivateChild');

    const id = Number(childRoute.queryParamMap.get('id'));
    const authDate = Number(childRoute.queryParamMap.get('auth_date'));
    const firstName = childRoute.queryParamMap.get('first_name');
    const lastName = childRoute.queryParamMap.get('last_name');
    const username = childRoute.queryParamMap.get('username');
    const photoUrl = childRoute.queryParamMap.get('photo_url');
    const hash = childRoute.queryParamMap.get('hash');
    const urlPath = window.location.pathname;

    if (!id || isNaN(id) || !authDate || isNaN(authDate) || !hash) {
      return true;
    }
    const queryParamKeys = childRoute.queryParamMap.keys;
    const credentials: TelegramCredentials = {
      asGuestIfFail: true,
      authDate,
      hash,
      id,
    };

    if (firstName !== null) {
      credentials.firstName = firstName;
    }

    if (lastName !== null) {
      credentials.lastName = lastName;
    }

    if (username !== null) {
      credentials.username = username;
    }

    if (photoUrl !== null) {
      credentials.photoUrl = photoUrl;
    }

    return of(false).pipe(
      tap(() => {
        this.store$.dispatch(authActions.signInByTelegramAction({
          credentials,
        }));
      }),
      switchMap(() => this.actions$.pipe(
        ofType(
          authActions.signInSuccessAction,
          authActions.signInFailureAction,
        ),
        take(1),
      )),
      map(() => {
        const excludes = [
          'auth',
          'id',
          'auth_date',
          'first_name',
          'last_name',
          'username',
          'photo_url',
          'hash',
        ];
        const queryParams: { [key: string]: any } = {};

        for (const key in queryParamKeys) {
          if (excludes.includes(key)) {
            continue;
          }
          queryParams[key] = childRoute.queryParamMap.get(key);
        }

        return this.router.createUrlTree(
          [urlPath],
          {
            queryParams,
            preserveFragment: true,
          },
        );
      }),
    );
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

}
