import {inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {
  first,
  map,
  tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import { convertKeysToCamel } from '@iterra/app-lib/utils';

import {
  Authorization,
  Device,
} from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';

const logger = new Logger('DeviceAuthApi');

@Injectable({
  providedIn: 'root',
})
export class DeviceAuthApi {
  private apiService = inject(ApiService);

  authorize(): Observable<Authorization> {
    return this.apiService.post<Authorization>(
      '/authentication/device/authorize',
    ).pipe(
      map(convertKeysToCamel),
      tap(authorization => logger.debug('authorize (authorization)', authorization)),
      first(),
    );
  }

  create(): Observable<Device> {
    return this.apiService.post<Device>(
      '/authentication/device/hash',
    ).pipe(
      map(convertKeysToCamel),
      tap(device => logger.debug('create (device)', device)),
      first(),
    );
  }
}
