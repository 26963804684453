import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as authSelectors from '../store/selectors/auth.selectors';


@Injectable()
export class DeviceHashInterceptor implements HttpInterceptor {

  constructor(
    private store$: Store,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {

    const rocketChatApi = 'chat.iterra.world';

    if (request.method === 'POST' && !request.url.includes(rocketChatApi)) {
      return this.store$.pipe(
        select(authSelectors.selectDevice),
        switchMap(device => {
          if (device) {
            request = request.clone({
              setHeaders: {
                'X-Device-Hash': device,
              },
            });
          }

          return next.handle(request);
        }),
      );
    }

    return next.handle(request);
  }
}

export const DeviceHashInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: DeviceHashInterceptor,
  multi: true,
};
