import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

import { ItButtonModule } from '@iterra/app-lib/it-button';
import { ItIconModule } from '@iterra/app-lib/it-icons';

@Component({
  selector: 'ui-auth-guest',
  standalone: true,
  imports: [
    ItButtonModule,
    ItIconModule,
  ],
  host: {
    class: 'auth-guest',
  },
  templateUrl: './auth-guest.component.html',
  styleUrls: ['./auth-guest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthGuestComponent {
  @Output() public clickButton = new EventEmitter<void>();

  protected onClickButton(): void {
    this.clickButton.emit();
  }
}
