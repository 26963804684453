import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Captcha,
  CaptchaValidateParams,
  Connection,
  ConnectionLog,
  ConnectionParams,
  HistoryParams,
  WidgetConnection,
  WidgetConnectionParams,
} from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';

import { ConnectionApi } from './api/connection.api';

const logger = new Logger('ConnectionService');

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private connectionApi = inject(ConnectionApi);

  public connect(params: ConnectionParams): Observable<Connection> {
    return this.connectionApi.create(params);
  }

  public connectWidget(
    params: WidgetConnectionParams,
  ): Observable<WidgetConnection> {
    return this.connectionApi.createWidgetConnection(params);
  }

  public fetchHistory(params: HistoryParams): Observable<ConnectionLog[] | null> {
    return this.connectionApi.fetchHistory(params);
  }

  public fetchCaptcha(): Observable<Captcha> {
    return this.connectionApi.fetchCaptcha();
  }

  public validateCaptcha(
    params: CaptchaValidateParams,
  ): Observable<Connection> {
    return this.connectionApi.validateCaptcha(params);
  }
}
