import {
    createReducer,
    on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { Code } from '../../schemas/phone.schemas';
import * as phoneActions from '../actions/phone.actions';

export const DEFAULT_PHONE_FEATURE_NAME = 'phone';

export interface PhoneReducerState {
    code: Code | null;
    error: ResponseError | null;
    isLoading: boolean;
}

const initialState: PhoneReducerState = {
    code: null,
    error: null,
    isLoading: false,
};

export const phoneReducer = createReducer(
    initialState,
    on(
        phoneActions.sendCodeAction,
        phoneActions.sendSignUpCodeAction,
        phoneActions.sendSignInCodeAction,
        phoneActions.sendPasswordResetCodeAction,
        phoneActions.validateCodeAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        phoneActions.sendCodeSuccessAction,
        phoneActions.sendSignUpCodeSuccessAction,
        phoneActions.sendSignInCodeSuccessAction,
        phoneActions.sendPasswordResetCodeSuccessAction,
        (state, {phone, sentAt}) => ({
            ...state,
            isLoading: false,
            code: {
                phone,
                sentAt,
            },
        }),
    ),
    on(
        phoneActions.validateCodeSuccessAction,
        (state, {code}) => ({
            ...state,
            isLoading: false,
            code,
        }),
    ),
    on(
        phoneActions.sendCodeFailureAction,
        phoneActions.sendSignUpCodeFailureAction,
        phoneActions.sendSignInCodeFailureAction,
        phoneActions.sendPasswordResetCodeFailureAction,
        phoneActions.validateCodeFailureAction,
        (state, {error}) => ({
            ...state,
            isLoading: false,
            error,
        }),
    ),
);
