export const captchaFigures = {
    '😀': 'https://dev.iterra.world/media/storage/2022/12/04/1989-z75kuO4WefFBVQDmcPMYI3qSlgUK2ET6ob1twJxH.png',
    '🤪': 'https://dev.iterra.world/media/storage/2022/12/04/2003-8jG4hcxEizlJKI5Y9UVkfDwvFWRLTqr3oZCaubH1.png',
    '💩': 'https://dev.iterra.world/media/storage/2022/12/04/1996-hbZP3JMYNiuXBoKxFvqzUwcH5AmEaVGdCDl4rytR.png',
    '🤡': 'https://dev.iterra.world/media/storage/2022/12/04/1980-1NVr9UGRbhqCJxmpB2jlsF8wIic346onDHOP5TMd.png',
    '👽': 'https://dev.iterra.world/media/storage/2022/12/04/1971-kLIzKG0WoeT6Ed1lpVvgNQ83C4xucit9ADyOPsFa.png',
    '🙈': 'https://dev.iterra.world/media/storage/2022/12/04/1992-CQexjZ51vfN8B2tOhYnkorMcKwJa7P0AdmlEViDb.png',
    '✊': 'https://dev.iterra.world/media/storage/2022/12/04/1985-SBcvtNR8pi2mbfg6X9yWZOszqGHdruTFeAlIJMDn.png',
    '🦷': 'https://dev.iterra.world/media/storage/2022/12/04/2002-jqi0L87kBSWGdUcDbQIAmatoEfTgPels3pRVynNr.png',
    '👁': 'https://dev.iterra.world/media/storage/2022/12/04/1983-QyAqT2K0ibHsXNpBwL3GRJ7x4cfEOukvDUaleZ8F.png',
    '🐶': 'https://dev.iterra.world/media/storage/2022/12/04/1982-dn1paSvMYRA3W0PsD6uQ9xeB7lcwy4OUIT8qmhrZ.png',
    '🐷': 'https://dev.iterra.world/media/storage/2022/12/04/1995-4mzCgA5HQ3McIVhUPbjBS9ufyid1YTDLn2kKwsWG.png',
    '🐔': 'https://dev.iterra.world/media/storage/2022/12/04/1977-0GbYt68MqTsdiX5ncA3Ulm9wJQSWH1NaoDFx7ky4.png',
    '🐸': 'https://dev.iterra.world/media/storage/2022/12/04/1987-mPDkISgXqKwC6BQcxOi9euAYjFs1zv4Lp3tl2ZrW.png',
    '🐙': 'https://dev.iterra.world/media/storage/2022/12/04/1994-GczBitPKlgu65AM3HpFk4TvjZeaROWbXCoyqJdm8.png',
    '🦋': 'https://dev.iterra.world/media/storage/2022/12/04/1975-5zZIEl3mbCY4anvW2Xue6gpDo7kxAtVFGjOMQ0Ps.png',
    '🌸': 'https://dev.iterra.world/media/storage/2022/12/04/1986-ukO4VqMen2ZtfIGST1Y7KiHWFXdcgzvP5J8o3ByU.png',
    '🌻': 'https://dev.iterra.world/media/storage/2022/12/04/1999-nRczpMojsDGQWAB6xKHLIPZw8a4rNT3vElCm5JFu.png',
    '🍏': 'https://dev.iterra.world/media/storage/2022/12/04/1973-FbB3KZD8NULkeIS2hnX4pyMxPOu7w1tfdq6mEWsH.png',
    '🍅': 'https://dev.iterra.world/media/storage/2022/12/04/2001-UrRydMuWpnHVEok1NLP7wg0a6fXvqKZAJOeFID9G.png',
    '🥐': 'https://dev.iterra.world/media/storage/2022/12/04/1981-sI3fDgRTWCN8oVtl2YPiBX109Z4cpJ6OQEMbk7yh.png',
    '🍗': 'https://dev.iterra.world/media/storage/2022/12/04/1991-PN9rVM4L73HWspZ0cORfqEel8bikTXxmgUa2FyDA.png',
    '🧱': 'https://dev.iterra.world/media/storage/2022/12/04/1974-jNdvRUVMf4K12HbeQA0xSkoPyswTG75OmCFhraBg.png',
    '⛺': 'https://dev.iterra.world/media/storage/2022/12/04/2000-QxUS4tLGhzf9l5bq1gAuDIjYPem7ETRaKroVyMpc.png',
    '♨': 'https://dev.iterra.world/media/storage/2022/12/04/1990-aT96G5EpIwzo4mhXRUHv0kqQyD1tJYPbMLjZcn37.png',
    '🚗': 'https://dev.iterra.world/media/storage/2022/12/04/1976-I7BlRmCHn1pjJKvs8egWLcyhOxoEPFwXMUfiGQ9S.png',
    '⚓': 'https://dev.iterra.world/media/storage/2022/12/04/1972-ZAtr2QfT6jevNah4cVsPpHBwYIzk0oGyJWMumSbl.png',
    '🚀': 'https://dev.iterra.world/media/storage/2022/12/04/1997-ED2JUFjw0bBriGmSl869toTvfHhk5czN4yWLaYun.png',
    '⏰': 'https://dev.iterra.world/media/storage/2022/12/04/1978-KwI5CFVrYvclxqiU6a4JN8e2BPybgHOWdk1XE3jo.png',
    '🌝': 'https://dev.iterra.world/media/storage/2022/12/04/1993-8pnjhN9sLcBtUWVPY6AzIqxMbOG0RvEgJf1oa3lK.png',
    '⭐': 'https://dev.iterra.world/media/storage/2022/12/04/1998-5cmbe8rdUkpfgHZIo2ECwB4D6PFWvR3GKQ0znyqL.png',
    '☁': 'https://dev.iterra.world/media/storage/2022/12/04/1979-zfpxed7G3NKonAl2iRFZhv8YtaD950wWk6E1HPcq.png',
    '🔥': 'https://dev.iterra.world/media/storage/2022/12/04/1984-lpzRXSQDnjAIdsu2NJPMZga1BxoL6eEtwFi5Cc34.png',
    '🎁': 'https://dev.iterra.world/media/storage/2022/12/04/1988-FPO5dVzscpSTWEMAKqjvR2u3x1DhgleBCb4tXnYJ.png',
};
