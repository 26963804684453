import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
} from '@angular/core';


@Component({
    selector: 'app-telegram-post',
    templateUrl: './telegram-post.component.html',
    styleUrls: ['./telegram-post.component.scss'],
})
export class TelegramPostComponent implements AfterViewInit, OnDestroy {

    @ViewChild('container') container?: ElementRef;
    script?: HTMLScriptElement;

    constructor() {
    }

    ngAfterViewInit(): void {
        if (this.script) {
            return;
        }
        this.script = document.createElement('script');
        this.script.src = 'https://telegram.org/js/telegram-widget.js?19';
        this.script.setAttribute('data-telegram-post', 'irkru/2702');
        this.script.setAttribute('data-width', '100%');
        this.container?.nativeElement.appendChild(this.script);
    }

    ngOnDestroy(): void {
        if (this.script) {
            this.container?.nativeElement.removeChild(this.script);
        }
    }

}
