import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';
import { Dictionary } from '@ngrx/entity/src/models';
import {
    createReducer,
    on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { Widget } from '../../schemas/widget.schemas';
import * as authActions from '../actions/auth.actions';
import * as connectionActions from '../actions/connection.actions';
import * as widgetActions from '../actions/widget.actions';

export const DEFAULT_WIDGET_FEATURE_NAME = 'widget';

export interface WidgetReducerState extends EntityState<Widget> {
    isLoading: boolean;
    error: ResponseError | null;
    currentWidgetId: number | string | null;
    widgetStores: Dictionary<any>;
}

export function selectWidgetId(widget: Widget): number {
    return widget.id;
}

export function sortByTitle(a: Widget, b: Widget): number {
    return a.title.localeCompare(b.title);
}

export const widgetAdapter: EntityAdapter<Widget> = createEntityAdapter<Widget>({
    selectId: selectWidgetId,
    sortComparer: sortByTitle,
});

export const initialState: WidgetReducerState = widgetAdapter.getInitialState({
    isLoading: false,
    error: null,
    currentWidgetId: null,
    widgetStores: {},
});

export const widgetReducer = createReducer(
    initialState,
    on(
        widgetActions.addWidget,
        (state, {widget}) => widgetAdapter.addOne(widget, state),
    ),
    on(
        widgetActions.setWidget,
        (state, {widget}) => widgetAdapter.setOne(widget, state),
    ),
    on(
        widgetActions.upsertWidget,
        (state, {widget}) => widgetAdapter.upsertOne(widget, state),
    ),
    on(
        widgetActions.addWidgets,
        (state, {widgets}) => widgetAdapter.addMany(widgets, state),
    ),
    on(
        widgetActions.upsertWidgets,
        (state, {widgets}) => widgetAdapter.upsertMany(widgets, state),
    ),
    on(
        widgetActions.updateWidget,
        (state, {update}) => widgetAdapter.updateOne(update, state),
    ),
    on(
        widgetActions.updateWidgets,
        (state, {updates}) => widgetAdapter.updateMany(updates, state),
    ),
    on(
        widgetActions.mapWidget,
        (state, {entityMap}) => widgetAdapter.mapOne(entityMap, state),
    ),
    on(
        widgetActions.mapWidgets,
        (state, {entityMap}) => widgetAdapter.map(entityMap, state),
    ),
    on(
        widgetActions.deleteWidget,
        (state, {id}) => widgetAdapter.removeOne(id, state),
    ),
    on(
        widgetActions.deleteWidgets,
        (state, {ids}) => widgetAdapter.removeMany(ids, state),
    ),
    on(
        widgetActions.deleteWidgetsByPredicate,
        (state, {predicate}) => widgetAdapter.removeMany(predicate, state),
    ),
    on(
        widgetActions.setWidgets,
        (state, {widgets}) => widgetAdapter.setAll(widgets, state),
    ),
    on(
        widgetActions.clearWidgets,
        state => widgetAdapter.removeAll(state),
    ),
    on(
        widgetActions.loadWidgetsAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        widgetActions.loadWidgetsSuccessAction,
        (state, {widgets}) => widgetAdapter.upsertMany(
            widgets,
            {
                ...state,
                isLoading: false,
            },
        ),
    ),
    on(
        widgetActions.loadWidgetsFailureAction,
        (state, {error}) => ({
            ...state,
            isLoading: false,
            error,
        }),
    ),
    on(
        widgetActions.setCurrentWidgetId,
        (state, {widgetId}) => ({
            ...state,
            currentWidgetId: widgetId,
        }),
    ),
    on(
        widgetActions.unsetCurrentWidgetId,
        state => ({
            ...state,
            currentWidgetId: null,
        }),
    ),
    on(
        widgetActions.setWidgetStore,
        (state, {name, content}) => {
            const widgetStores = {...state.widgetStores};

            widgetStores[name] = content;

            return {
                ...state,
                widgetStores,
            };
        },
    ),
    on(
        authActions.signOutSuccessAction,
        state => ({
            ...state,
            widgetStores: {},
        }),
    ),
    on(
        connectionActions.disconnectAction,
        (state, {locationId}) => {
            const widgetStores: { [key: string]: any } = {};
            const widgets = Object.keys(state.widgetStores);

            for (const widget of widgets) {
                const widgetStore = state.widgetStores[widget];
                const ids = Object.keys(widgetStore);
                const store: { [key: number]: any } = {};

                for (const id of ids) {
                    const location = Number(id);
                    if (location !== locationId) {
                        store[location] = widgetStore[location];
                    }
                }

                widgetStores[widget] = store;
            }

            return {
                ...state,
                widgetStores,
            };
        },
    ),
);
