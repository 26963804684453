import {
  AfterViewInit,
  Component,
  OnInit,
} from '@angular/core';

import { Logger } from '@iterra/app-lib/services';

const logger = new Logger('TelegramBotComponent');

@Component({
  selector: 'app-telegram-bot',
  templateUrl: './telegram-bot.component.html',
  styleUrls: ['./telegram-bot.component.scss'],
})
export class TelegramBotComponent implements AfterViewInit {
  data?: any;

  ngAfterViewInit(): void {
    this.loadScript('https://telegram.org/js/telegram-web-app.js').then(r => {
      logger.debug('loadScript result', r);
      // @ts-ignore
      logger.debug('window.Telegram.WebApp', window.Telegram.WebApp);
      // @ts-ignore
      this.data = window.Telegram.data;
      logger.debug('this.data', this.data);
    });
  }

  loadScript(src: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.head.prepend(script);
      document.head.prepend(script);
    });
  }

}
