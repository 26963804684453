import {inject, Injectable} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import {
  map,
  tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

import * as aboutActions from '../actions/about.actions';

const logger = new Logger('AboutEffects');

@Injectable()
export class AboutEffects {
  private actions$ = inject(Actions);

  loadAboutsSuccessEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(aboutActions.loadAboutsSuccessAction),
      tap(({abouts}) => logger.debug('loadAboutsSuccessEffect (abouts)', abouts)),
      map(({abouts}) => aboutActions.upsertAbouts({abouts})),
    ),
  );
}
