import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  select,
  Store,
} from '@ngrx/store';
import {
  Observable,
  of,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { ConnectionLog } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';

import { Capsule } from '../schemas/capsule.schemas';
import { Connection } from '../schemas/connection.schemas';
import {
  Location,
  LocationHistory,
  LocationHistoryGroup,
} from '../schemas/location.schemas';
import { ConnectionService } from '../services/connection.service';
import { StoreService } from '../services/store.service';
import * as connectionActions from '../store/actions/connection.actions';
import * as connectionSelectors from '../store/selectors/connection.selectors';
import { BaseWidgetState } from '../widgets/abstract-widget.store';

const logger = new Logger('ConnectionFacade');

@Injectable({
  providedIn: 'root',
})
export class ConnectionFacade {
  private connectionService = inject(ConnectionService);
  private store$ = inject(Store);
  private storeService = inject(StoreService);

  get connections$(): Observable<Connection[]> {
    return this.store$.pipe(
      select(connectionSelectors.selectConnections),
    );
  }

  get connectedLocationsIds$(): Observable<number[]> {
    return this.store$.pipe(
      select(connectionSelectors.selectConnectedLocationsIds),
    );
  }

  get isConnected$(): Observable<boolean> {
    return this.store$.pipe(
      select(connectionSelectors.selectIsConnected),
    );
  }

  get currentConnection$(): Observable<Connection | null> {
    return this.store$.pipe(
      select(connectionSelectors.selectCurrentConnection),
    );
  }

  get currentLocation$(): Observable<Location | null> {
    return this.store$.pipe(
      select(connectionSelectors.selectCurrentLocation),
    );
  }

  get currentCapsule$(): Observable<Capsule | null> {
    return this.store$.pipe(
      select(connectionSelectors.selectCurrentCapsule),
    );
  }

  get currentLocationId$(): Observable<number | undefined> {
    return this.store$.pipe(
      select(connectionSelectors.selectCurrentLocationId),
    );
  }

  connect(location: Location): void {
    const params = {
      locationId: location.id,
      portId: 0,
    };
    this.store$.dispatch(connectionActions.connectAction({params}));
  }

  disconnect(location: Location): void {
    this.store$.dispatch(connectionActions.disconnectAction({locationId: location.id}));
  }

  fetchHistory(): Observable<LocationHistoryGroup[]> {
    return this.connectionService.fetchHistory({limit: 0}).pipe(
      tap((history: ConnectionLog[] | null) => logger.debug('fetchHistory(history)', history)),
      switchMap((history: ConnectionLog[] | null) => {

        if (!history) {
          return of([]);
        }

        const pipe = new DatePipe('en-US');
        const uniqueIds: number[] = [];

        const storage: { [key: string]: any } = {};
        const dateIdKeys: { [key: string]: number[] } = {};
        const dateKeys: string[] = [];

        history.forEach(item => {
          const date = pipe.transform(item.date + 'Z', 'dd.MM.yy') as string;
          if (!dateKeys.includes(date)) {
            dateKeys.push(date);
          }

          if (!(date in storage)) {
            storage[date] = {};
          }

          if (!(date in dateIdKeys)) {
            dateIdKeys[date] = [];
          }

          const existItem = storage[date][item.locationId];

          if (!existItem || existItem.date < item.date) {
            storage[date][item.locationId] = item;
          }

          if (!dateIdKeys[date].includes(item.locationId)) {
            dateIdKeys[date].push(item.locationId);
          }

          if (!uniqueIds.includes(item.locationId)) {
            uniqueIds.push(item.locationId);
          }

        });

        return this.storeService.fetchLocations(uniqueIds).pipe(
          tap(locations => logger.debug('history (locations)', locations)),
          map(locations => {

            const result: LocationHistoryGroup[] = [];

            dateKeys.forEach(date => {
              const items: LocationHistory[] = [];

              dateIdKeys[date].forEach(dateIdKey => {
                const item = storage[date][dateIdKey];
                const location = locations.find(data => data.id === item.locationId);
                if (location) {
                  items.push({
                    location: {...location, isFavorite: location.isFavorite ?? false},
                    lastVisitDate: item.date,
                  });
                }
              });

              if (items.length) {
                result.push({date, items});
              }
            });

            return result;
          }),
        );

      }),
    );
  }

  fetchLocationWidgetState$<T extends BaseWidgetState>(
    locationId: number,
    widgetId: number | string,
  ): Observable<T | null> {
    return this.currentConnection$.pipe(
      map((connection: Connection | null) =>
        connection?.widgetStates?.find(x =>
          x.locationId === locationId && x.widgetId === widgetId,
        ) as T || null,
      ),
    );
  }

  setLocationWidgetState<T extends BaseWidgetState>(
    locationId: number,
    widgetId: number | string,
    state: T,
  ): void {
    this.store$.pipe(
      select(connectionSelectors.selectConnection(locationId)),
      take(1),
      tap((connection?: Connection) => {
        if (!connection) {
          return;
        }

        const widgetStates = connection.widgetStates?.filter(x =>
          x.locationId === locationId && x.widgetId === widgetId,
        ) || [];

        widgetStates.push(state);

        this.store$.dispatch(
          connectionActions.upsertConnectionAction({
            connection: {
              ...connection,
              widgetStates,
            },
          }),
        );
      }),
    ).subscribe();
  }
}
