import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AppComponent } from '../app.component';


@Injectable()
export class DeactivateGuard implements CanDeactivate<AppComponent> {
    canDeactivated = true;

    allowLeavePage(): void {
        this.canDeactivated = true;
    }

    disallowLeavePage(): void {
        this.canDeactivated = false;
    }

    canDeactivate(): boolean {
        return this.canDeactivated;
    }
}
