import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { Captcha, Figure } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';

import { Connection } from '../../../schemas/connection.schemas';
import { ConnectionService } from '../../../services/connection.service';

const logger = new Logger('CaptchaFormComponent');

@Component({
  selector: 'app-captcha-form',
  templateUrl: './captcha-form.component.html',
  styleUrls: ['./captcha-form.component.scss'],
})
export class CaptchaFormComponent implements OnInit, OnDestroy {
  captcha?: Captcha;
  figures: Figure[] = [];
  subscriptions: Subscription[] = [];
  selectedFigures: Set<Figure>;
  figuresCount = 3;
  isEnabled = true;

  @Output() captchaSuccess = new EventEmitter<Connection>();

  constructor(
    private readonly connectionService: ConnectionService,
  ) {
    this.selectedFigures = new Set<Figure>();
  }

  ngOnInit(): void {
    this.loadCaptcha();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(
        subscription => subscription.unsubscribe(),
      );
      this.subscriptions = [];
    }
  }

  loadCaptcha(): void {
    this.subscriptions.push(
      this.connectionService.fetchCaptcha().subscribe(captcha => {
        this.captcha = captcha;
        this.figures = captcha.context.figures;
      }),
    );
  }

  public refreshCaptcha(): void {
    this.loadCaptcha();
  }

  validateCaptcha(figures: Figure[]): void {
    this.connectionService.validateCaptcha({
      figures,
    }).subscribe({
      next: connection => {
        logger.debug('connection', connection);

        this.captchaSuccess.emit(connection);
      },
      error: error => {
        logger.error('error', error);

        this.selectedFigures.clear();
        this.isEnabled = true;
        this.loadCaptcha();
      },
    });
  }

  onClickFigure(figure: Figure): void {
    if (!this.isEnabled) {
      return;
    }

    this.selectedFigures.has(figure)
      ? this.selectedFigures.delete(figure)
      : this.selectedFigures.add(figure);

    if (this.selectedFigures.size >= this.figuresCount) {
      this.isEnabled = false;
      this.validateCaptcha([...this.selectedFigures]);
    }
  }

  isSelected(figure: Figure): boolean {
    return this.selectedFigures.has(figure);
  }

}
