<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer #drawer mode="over">
    <a
        class="sidenav-item"
        (click)="goToLink($event, '/account/settings')"
    >
      Настройки аккаунта
    </a>

    <it-button
        class="sidenav-item"
        (clicked)="signOut()"
    >
      Выйти
    </it-button>
  </mat-drawer>
  <mat-drawer-content>
    <div
        class="layout-wrapper"
        [style.background-image]="isConnectCapsule ? backgroundImageStyle : null"
    >
      <div class="layout-wrapper__filter"></div>
      <div class="layout-container">
        <app-layout-iterra-navbar
            [title]="isConnectCapsule ? currentLocation?.title : null"
            (toggleSidenav)="toggleSidenav()"
        />

        <div *ngIf="isGuest" class="guest">
          <ui-auth-guest (clickButton)="goToAuth()"/>
        </div>

        <div class="iterra-container">
          <router-outlet></router-outlet>
        </div>

        <app-layout-iterra-footer/>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
