<div class="layout-wrapper">
  <div class="layout-container">

    <div class="iterra-container">
      <router-outlet></router-outlet>
    </div>

    <app-layout-iterra-footer />
  </div>
</div>
