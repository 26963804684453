import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { ALIAS_WIDGET_MAP } from '../../../environments/environment';
import { Connection } from '../../schemas/connection.schemas';
import {
    ConnectionReducerState,
    DEFAULT_CONNECTION_FEATURE_NAME,
} from '../reducers/connection.reducer';
import * as authSelectors from './auth.selectors';
import * as capsuleSelectors from './capsule.selectors';
import * as locationSelectors from './location.selectors';
import {Logger} from '@iterra/app-lib/services';

const logger = new Logger('ConnectionSelectors');

const selectConnectionFeature = createFeatureSelector<ConnectionReducerState>(DEFAULT_CONNECTION_FEATURE_NAME);

export const selectConnections = createSelector(
    selectConnectionFeature,
    state => state.connections,
);

export const selectConnection = (locationId?: number) =>
    createSelector(
        selectConnections,
        (connections: Connection[]) =>
            connections.find(connection => locationId && connection.locationId === locationId),
    );

export const selectConnectedLocationsIds = createSelector(
    selectConnections,
    (connections: Connection[]) => connections.map(connection => connection.locationId),
);

export const selectCurrentLocationId = createSelector(
    selectConnectionFeature,
    state => state.currentLocationId,
);

export const selectCurrentLocation = createSelector(
    selectCurrentLocationId,
    locationSelectors.selectLocationEntities,
    (currentLocationId, locationEntities) => {
        return currentLocationId
            ? (locationEntities[currentLocationId] || null)
            : null;
    },
);

export const selectCurrentCapsuleId = createSelector(
    selectConnectionFeature,
    state => state.currentCapsuleId,
);

export const selectCurrentCapsule = createSelector(
    selectCurrentCapsuleId,
    capsuleSelectors.selectCapsuleEntities,
    (currentCapsuleId, capsuleEntities) => {
        return currentCapsuleId
            ? (capsuleEntities[currentCapsuleId] || null)
            : null;
    },
);

export const selectCurrentConnection = createSelector(
    selectConnections,
    selectCurrentLocationId,
    (connections: Connection[], currentLocationId?: number) =>
        connections.find(connection => connection.locationId === currentLocationId) || null,
);

export const selectCurrentAccessToken = createSelector(
    selectCurrentConnection,
    (currentConnection: Connection | null) => currentConnection?.accessToken,
);

export const selectWidgetToken = createSelector(
    selectCurrentConnection,
    (currentConnection: Connection | null, widgetId: number | string) =>
        currentConnection?.widgetConnections?.find(x =>
            x.widgetId === (typeof widgetId === 'string'
                ? (ALIAS_WIDGET_MAP[widgetId]?.id || widgetId)
                : widgetId),
        )?.accessToken,
);

export const selectIsConnecting = createSelector(
    selectConnectionFeature,
    state => state.isConnecting,
);

export const selectIsConnected = createSelector(
    selectCurrentConnection,
    currentConnection => !!currentConnection,
);

export const selectError = createSelector(
    selectConnectionFeature,
    state => state.error,
);

export const selectActiveAccessToken = createSelector(
    selectCurrentConnection,
    authSelectors.selectAccessToken,
    (
        currentConnection: Connection | null,
        defaultAccessToken?: string,
        params?: { widgetId?: number | string },
    ) => {
        logger.debug('selectActiveAccessToken params', params);
        logger.debug('selectActiveAccessToken currentConnection?.widgetConnections', currentConnection?.widgetConnections);

        const widgetConnection = currentConnection?.widgetConnections?.find(item => {
            const widgetId = typeof params?.widgetId === 'string'
                ? (ALIAS_WIDGET_MAP[params.widgetId]?.id || params?.widgetId)
                : params?.widgetId;

            return widgetId && item.widgetId === widgetId;
        });

        logger.debug('connections widgetConnection', widgetConnection);

        if (widgetConnection?.accessToken) {
            logger.debug('widgetConnection', widgetConnection);
            return widgetConnection.accessToken;
        }

        if (currentConnection?.accessToken) {
            logger.debug('currentConnection', currentConnection);
            return currentConnection.accessToken;
        }

        logger.debug('defaultAccessToken', defaultAccessToken);
        return defaultAccessToken;
    },
);
