import { LoadChildren } from '@angular/router';
import { Emoji } from './schemas/emoji.schemas';
import { ScreenCreateParams } from './schemas/screen.schemas';


export const defaultYandexCounter = 89146328;

export const constants = {
  toastr: {
    positionClass: 'toast-top-full-width',
  },
  tagMore: {
    id: 0,
    icon: 'assets/images/panel-category-icons/icon-more.svg',
    title: 'Еще',
    rating: 0,
  },
};

export const imageThumb = [350, 500, 800];

export const treeColors: string[] = [
  // service generation tones #example
  // https://www.shadegenerator.com/7DA9C0
  'rgba(142, 142, 124, 1)',
  'rgba(142, 142, 142, 0.5)',
  'rgba(142, 142, 142, 0.3)',
];

export const defaultMaxUploadImages = 5;
export const defaultMaxTextLength = 5000;
export const defaultLimit = 24;

export const defaultCompressMaxHeight = 1000;
export const defaultCompressMaxWidth = 1000;

export const MapboxStyles = {
  mapInitOptions: {
    container: 'map',
    zoom: 12,
    center: {
      lng: 104.298752,
      lat: 52.265131,
    },
    attributionControl: false,
    maxZoom: 15,
    dragRotate: false,
  },
  flyTo: {
    padding: 30,
    // zoom: 15,
    essential: true,
  },
  circles: {
    'circle-radius': 8,
    // circle color from property in geojson
    // 'circle-color': {
    //     property: 'color',
    //     type: 'identity',
    // },
    'circle-color': ['case',
      ['boolean', ['feature-state', 'clicked'], false],
      'green', // clicked color
      '#0080ff', // default color
    ],
    'circle-stroke-width': 2,
    'circle-stroke-color': ['case',
      ['boolean', ['feature-state', 'clicked'], false],
      '#b4eb34', // clicked stroke color
      '#fff', // default stroke color
    ],
  },
  points: {
    'circle-radius': 8,
    // circle color from property in geojson
    // 'circle-color': {
    //     property: 'color',
    //     type: 'identity',
    // },
    'circle-color': ['case',
      ['boolean', ['feature-state', 'selected'], false],
      'green', // selected color
      '#0080ff', // default color
    ],
    'circle-stroke-width': 2,
    'circle-stroke-color': ['case',
      ['boolean', ['feature-state', 'selected'], false],
      '#b4eb34', // selected stroke color
      '#fff', // default stroke color
    ],
  },
  circlesText: {
    'text-field': '{title}',
    'text-font': ['Open Sans Regular'],
    'text-offset': [0, 0.6],
    'text-anchor': 'top',
    'text-size': 14,
    'text-allow-overlap': true,
  },
  clusters: {
    'circle-radius': [
      'step',
      ['zoom'],
      30,
      10,
      20,
      13,
      15,
      15,
      10,
    ],
    'circle-color': [
      'step',
      ['get', 'point_count'],
      'transparent',
      2, // if cluster point count >= 2 && < 5 then cluster color #51bbd6
      '#51bbd6',
      5, // if cluster point count >= 5 && < 100 then cluster color #26afc9
      '#26afc9',
      100, // if cluster point count >= 100 then cluster color #10a6cb
      '#10a6cb',
    ],
    'circle-stroke-width': 2,
    'circle-stroke-color': ['case',
      ['boolean', ['feature-state', 'selected'], false],
      '#0a810e', // selected stroke color
      '#fff', // default stroke color
    ],
  },
};

export const spinner = {
  color: '#236eff',
  size: 20,
  distanceForRefresh: 40,
  minScroll: 3,
};

export const weekdays: string[] = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
export const screenParams: { [key: string]: ScreenCreateParams } = {
  admin: {
    title: '',
    icon: 'fas fa-crown',
    position: 1,
  },
  user: {
    title: '',
    icon: 'fas fa-user-alt',
    position: 2,
  },
  stuff: {
    title: '',
    icon: 'fas fa-cogs',
    position: 3,
  },
  guest: {
    title: '',
    icon: 'fas fa-user-secret',
    position: 4,
  },
};

export const widgetScreenMap: { [key: number]: string } = {
  1: 'guest',
  10: 'user',
  100: 'staff',
  1000: 'admin',
};

export const APP_THEME = 'iterra';

export const reactions: Emoji[] = [
  {
    id: '🤩',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1960-iUlHFCwT4nMq0tb3eGVDPzcsQZLxBmXh26gpfoIE.json',
  },
  {
    id: '😂',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1967-B7eRKlgfFQdAaZrM8W4N3XomnV1ObUctuqDHsISP.json',
  },
  {
    id: '😎',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1961-xsuCT4NhUEK6kcHyBR7IgiO35qJo0VXvtbZwrAlF.json',
  },
  {
    id: '🤨',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1963-NBR1TL75DlGvYtasyr9PWzFIkmCVOHdJbMiZxg8p.json',
  },
  {
    id: '💗',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1966-ytfOWq5XJ9QoIxTVGCcFlE3PngmYLbrKiZNDu4kU.json',
  },
  {
    id: '🔥',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1959-XxP32WcrB7sdkf8gb6YKv5ShDCiLoJnpA0yGQMa1.json',
  },
  {
    id: '💩',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1965-kAH0pL21bsUmKCiQtDgYzuNwES68WjxGJ5ZRoXfl.json',
  },
  {
    id: '🤡',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1964-u7WKzdstg6C9nFVYiR4mrwU2S1LG5XNMaOEDbQx0.json',
  },
  {
    id: '😡',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1962-zFMG85ULCrq4I2bj9e3tf1lxnPVSDoJBpQu0EAsT.json',
  },
  {
    id: '⭐',
    src: 'https://dev.iterra.world/media/storage/2022/11/29/1958-PbhHMjuVlEWUpfYma3AkLdNCDJ8Ov0x41IRKgZnQ.json',
  },
];

export const WIDGET_MODULE_LOADER_MAP: { [key: string]: LoadChildren } = {
  'posting': () => import('./widgets/posting/posting-user-widget.module')
    .then(m => m.PostingUserWidgetModule),
  'posting-admin': () => import('./widgets/posting/posting-admin-widget.module')
    .then(m => m.PostingAdminWidgetModule),
  'location-settings': () => import('./widgets/location-settings/location-settings-widget.module')
    .then(m => m.LocationSettingsWidgetModule),
  'music-library': () => import('./widgets/music-library/music-library-widget.module')
    .then(m => m.MusicLibraryWidgetModule),
  'vk': () => import('./widgets/vk/vk-user-widget.module')
    .then(m => m.VkUserWidgetModule),
  'vk-guest': () => import('./widgets/vk/vk-guest-widget.module')
    .then(m => m.VkGuestWidgetModule),
  'telegram': () => import('./widgets/telegram/telegram-user-widget.module')
    .then(m => m.TelegramUserWidgetModule),
  'telegram-guest': () => import('./widgets/telegram/telegram-guest-widget.module')
    .then(m => m.TelegramGuestWidgetModule),
  'telegram-comments': () => import('./widgets/telegram-comments/telegram-comments-user-widget.module')
    .then(m => m.TelegramCommentsUserWidgetModule),
};
