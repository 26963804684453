import { Injectable } from '@angular/core';

import { Logger } from '@iterra/app-lib/services';

import { imageThumb } from '../app.constants';
import { ImageSize } from '../schemas/image.schemas';

const logger = new Logger('ImageService');

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  getImageSize(width: number, height: number): ImageSize {
    const result: ImageSize = {
      width: 0,
      height: 0,
    };

    if (width - height > 0) {
      result.width = this.getNearestLargerSize(width);
    } else {
      result.height = this.getNearestLargerSize(height);
    }

    return result;
  }

  getNearestLargerSize(size: number): number {
    const result = Math.min(...imageThumb.filter(v => v > size));
    logger.debug('result', result);
    return String(result) === 'Infinity' ? 1024 : result;
  }
}
