import {
    Component,
    Input,
} from '@angular/core';

import { Figure } from '@iterra/app-lib/schemas';

import { captchaFigures } from '../captcha.constants';

@Component({
    selector: 'app-captcha-figure',
    templateUrl: './captcha-figure.component.html',
    styleUrls: ['./captcha-figure.component.scss'],
})
export class CaptchaFigureComponent {
    @Input() figure?: Figure;
    @Input() isSelected = false;

    public figuresForCaptcha: { [key: string]: string } = captchaFigures;
}
