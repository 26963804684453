import {
    createReducer,
    on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import {
    Authorization,
    User,
} from '../../schemas/auth.schemas';
import * as authActions from '../actions/auth.actions';

export const DEFAULT_AUTH_FEATURE_NAME = 'auth';

export interface AuthReducerState {
    isLoading: boolean;
    isFirstRun: boolean;
    authorization: Authorization | null;
    error: ResponseError | null;
    user: User | null;
    device: string | null;
    deepLink: string | null;
}

const initialState: AuthReducerState = {
    isLoading: false,
    isFirstRun: false,
    authorization: null,
    error: null,
    user: null,
    device: null,
    deepLink: null,
};

export const authReducer = createReducer(
    initialState,
    on(
        authActions.signUpAction,
        authActions.signInAction,
        authActions.signOutAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        authActions.signUpSuccessAction,
        authActions.signInSuccessAction,
        authActions.setAuthorizationAction,
        (state, {authorization}) => ({
            ...state,
            isLoading: false,
            isFirstRun: false,
            authorization,
        }),
    ),
    on(
        authActions.signUpFailureAction,
        authActions.signInFailureAction,
        (state, {error}) => ({
            ...state,
            isLoading: false,
            error,
        }),
    ),
    on(
        authActions.signOutSuccessAction,
        authActions.signOutFailureAction,
        state => ({
            ...initialState,
            device: state.device,
            deepLink: state.deepLink,
        }),
    ),
    on(
        authActions.setDeviceAction,
        (state, {device}) => ({
            ...state,
            device,
        }),
    ),
    on(
        authActions.setInitAction,
        (state, {device, deepLink, authorization}) => ({
            ...state,
            device,
            deepLink,
            authorization,
            isFirstRun: device === null,
        }),
    ),
    on(
        authActions.setUserAction,
        (state, {user}) => ({
            ...state,
            user,
        }),
    ),
    on(
        authActions.setDeepLinkAction,
        (state, {deepLink}) => ({
            ...state,
            deepLink,
        }),
    ),
);
