import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { convertKeysToSnake, formatErrors } from '@iterra/app-lib/utils';

import { environment } from '../../../environments/environment';

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  'Accept': 'application/json',
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private http = inject(HttpClient);
  private alertService = inject(ToastrService);

  private prefix = '';

  private makeUri(path: string): string {
    return `${environment.api.url}${this.prefix}${path}`;
  }

  private handleErrors(excludes: number[], response: any): Observable<never> {
    if (!excludes.includes(response?.status)) {
      const error = formatErrors(response);
      this.alertService.error(error.message);
    }

    return throwError(response);
  }

  get<T>(
    path: string,
    objectParams: { [param: string]: string | number | ReadonlyArray<string> },
    excludes: number[] = [],
  ): Observable<T> {
    const params = new HttpParams({
      fromObject: convertKeysToSnake(objectParams),
    });

    return this.http.get<T>(this.makeUri(path), {
        headers: {
          Accept: 'application/json',
        },
        params,
      },
    )
      .pipe(catchError(this.handleErrors.bind(this, excludes)));
  }

  put<T>(path: string, body: object = {}, excludes: number[] = []): Observable<T> {
    return this.http.put<T>(this.makeUri(path), body, {headers})
      .pipe(catchError(this.handleErrors.bind(this, excludes)));
  }

  patch<T>(path: string, body: object = {}, excludes: number[] = []): Observable<T> {
    return this.http.patch<T>(this.makeUri(path), body, {headers})
      .pipe(catchError(this.handleErrors.bind(this, excludes)));
  }

  post<T>(path: string, body: object = {}, excludes: number[] = []): Observable<T> {
    return this.http.post<T>(this.makeUri(path), body, {headers})
      .pipe(catchError(this.handleErrors.bind(this, excludes)));
  }

  delete<T>(path: string, excludes: number[] = []): Observable<T> {
    return this.http.delete<T>(this.makeUri(path))
      .pipe(catchError(this.handleErrors.bind(this, excludes)));
  }

}
