import { Injectable } from '@angular/core';

import { Logger } from '@iterra/app-lib/services';

const logger = new Logger('HistoryEffects');

@Injectable()
export class HistoryEffects {
    // addHistoryEffect$ = createEffect(
    //     () => this.actions$.pipe(
    //         ofType(
    //             connectionActions.connectSuccessAction,
    //             connectionActions.setCurrentConnectionAction,
    //         ),
    //         tap(({connection}) => logger.debug('addHistoryEffect (connection)', connection)),
    //         switchMap(({connection}) => this.store$.pipe(
    //             select(locationSelectors.selectLocation, {id: connection.locationId}),
    //             tap(location => logger.debug('connection location', location)),
    //             filter((location) => !location?.isDynamic),
    //             map(() => {
    //                 const historyJSON = localStorage.getItem('history');
    //                 const history = historyJSON
    //                     ? JSON.parse(historyJSON)
    //                     : [];
    //                 const connectionHistory = {
    //                     locationId: connection.locationId,
    //                     date: new Date().getTime(),
    //                 };
    //
    //                 logger.debug('addHistoryEffect (connectionHistory)', connectionHistory);
    //                 history.unshift(connectionHistory);
    //                 localStorage.setItem('history', JSON.stringify(history));
    //             }),
    //         )),
    //     ),
    //     {dispatch: false},
    // );

}
