import { Dictionary } from '@ngrx/entity/src/models';
import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { Capsule } from '../../schemas/capsule.schemas';
import {
    capsuleAdapter,
    CapsuleReducerState,
    DEFAULT_CAPSULE_FEATURE_NAME,
} from '../reducers/capsule.reducer';


const selectCapsuleFeature = createFeatureSelector<CapsuleReducerState>(DEFAULT_CAPSULE_FEATURE_NAME);
const capsuleSelectors = capsuleAdapter.getSelectors(selectCapsuleFeature);

export const selectCapsuleIds = capsuleSelectors.selectIds;
export const selectCapsuleEntities = capsuleSelectors.selectEntities;
export const selectAllCapsules = capsuleSelectors.selectAll;
export const selectCapsuleTotal = capsuleSelectors.selectTotal;

export const selectCapsules = createSelector(
    selectAllCapsules,
    selectCapsuleEntities,
    (
        allCapsules: Capsule[],
        capsuleEntities: Dictionary<Capsule>,
        params: { capsuleIds?: number[] },
    ) => {
        const capsules: Capsule[] = [];

        if (params.capsuleIds?.length) {
            for (const id of params.capsuleIds) {
                const capsule = capsuleEntities[id];

                if (capsule) {
                    capsules.push(capsule);
                }
            }
        }

        return capsules;
    },
);

export const selectCapsule = createSelector(
    selectCapsuleEntities,
    (capsuleEntities: Dictionary<Capsule>, props: { id: number | string }) => capsuleEntities[props.id] || null,
);
