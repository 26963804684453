import { Dictionary } from '@ngrx/entity/src/models';
import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { LocationGeo, LocationTag, Tag, WorkTable, WorkTableWidget } from '@iterra/app-lib/schemas';

import { Location } from '../../schemas/location.schemas';
import {
    DEFAULT_LOCATION_FEATURE_NAME,
    locationAdapter,
    locationGeoAdapter,
    LocationReducerState,
    locationTagAdapter,
    tagAdapter,
    workTableAdapter,
    workTableWidgetAdapter,
} from '../reducers/location.reducer';

const selectLocationFeature = createFeatureSelector<LocationReducerState>(DEFAULT_LOCATION_FEATURE_NAME);


// Locations

const locationSelectors = locationAdapter.getSelectors(selectLocationFeature);

export const selectLocationIds = locationSelectors.selectIds;
export const selectLocationEntities = locationSelectors.selectEntities;
export const selectAllLocations = locationSelectors.selectAll;
export const selectLocationTotal = locationSelectors.selectTotal;

export const selectLocations = createSelector(
    selectAllLocations,
    selectLocationEntities,
    (
        allLocations: Location[],
        locationEntities: Dictionary<Location>,
        params: { locationIds?: number[] },
    ) => {
        const locations: Location[] = [];

        if (params.locationIds?.length) {
            for (const id of params.locationIds) {
                const location = locationEntities[id];

                if (location) {
                    locations.push(location);
                }
            }
        }

        return locations;
    },
);

export const selectRootLocationTree = createSelector(
    selectAllLocations,
    selectLocationEntities,
    (
        allLocations: Location[],
        locationEntities: Dictionary<Location>,
        params: { locationId: number },
    ) => {
        const locations: Location[] = [];

        if (params.locationId > 0) {
            for (const location of allLocations) {
                const path = location.path.split(',').map(Number) || [];

                if (path[0] === params.locationId) {
                    locations.push(location);
                }
            }

            locations.sort((a: Location, b: Location) => {
                    if (a?.path && b?.path && a?.path > b?.path) {
                        return 1;
                    }
                    return -1;
                },
            );
        }

        return locations;
    },
);


export const selectLocation = createSelector(
    selectLocationEntities,
    (locationEntities: Dictionary<Location>, props: { id: number }) => locationEntities[props.id] || null,
);


// Location tags

export const selectLocationTagsState = createSelector(
    selectLocationFeature,
    state => state.locationTags,
);

const locationTagSelectors = locationTagAdapter.getSelectors(selectLocationTagsState);

export const selectLocationTagIds = locationTagSelectors.selectIds;
export const selectLocationTagEntities = locationTagSelectors.selectEntities;
export const selectLocationTagAll = locationTagSelectors.selectAll;
export const selectLocationTagTotal = locationTagSelectors.selectTotal;

export const selectLocationsTags = createSelector(
    selectLocationTagAll,
    (
        locationTags: LocationTag[],
        props: { locationIds: number[] },
    ) => locationTags.filter(
        locationTag => props.locationIds.includes(locationTag.locationId),
    ),
);

export const selectLocationTags = createSelector(
    selectLocationTagAll,
    (
        locationTags: LocationTag[],
        props: { locationId: number },
    ) => locationTags.filter(
        locationTag => locationTag.locationId === props.locationId,
    ),
);


// Tags

export const selectTagsState = createSelector(
    selectLocationFeature,
    state => state.tags,
);

const tagSelectors = tagAdapter.getSelectors(selectTagsState);

export const selectTagIds = tagSelectors.selectIds;
export const selectTagEntities = tagSelectors.selectEntities;
export const selectTagAll = tagSelectors.selectAll;
export const selectTagTotal = tagSelectors.selectTotal;

export const selectTags = createSelector(
    selectTagAll,
    (
        tags: Tag[],
        props: { tagIds: number[] },
    ) => tags.filter(tag => props.tagIds.includes(tag.id)),
);

export const selectTag = createSelector(
    selectTagEntities,
    (
        tagEntities: Dictionary<Tag>,
        props: { tagId: number },
    ) => tagEntities[props.tagId] || null,
);


// Location geo

export const selectLocationGeosState = createSelector(
    selectLocationFeature,
    state => state.locationGeos,
);

const locationGeoSelectors = locationGeoAdapter.getSelectors(selectLocationGeosState);

export const selectLocationGeoIds = locationGeoSelectors.selectIds;
export const selectLocationGeoEntities = locationGeoSelectors.selectEntities;
export const selectLocationGeoAll = locationGeoSelectors.selectAll;
export const selectLocationGeoTotal = locationGeoSelectors.selectTotal;

export const selectLocationsGeos = createSelector(
    selectLocationGeoAll,
    (
        locationGeos: LocationGeo[],
        props: { locationIds: number[] },
    ) => locationGeos.filter(
        locationGeo => props.locationIds.includes(locationGeo.locationId),
    ),
);

export const selectLocationGeo = createSelector(
    selectLocationGeoAll,
    (
        locationGeos: LocationGeo[],
        props: { locationId: number },
    ) => locationGeos.find(
        locationGeo => locationGeo.locationId === props.locationId,
    ),
);


// Work tables

export const selectWorkTablesState = createSelector(
    selectLocationFeature,
    state => state.workTables,
);

const workTableSelectors = workTableAdapter.getSelectors(selectWorkTablesState);

export const selectWorkTableIds = workTableSelectors.selectIds;
export const selectWorkTableEntities = workTableSelectors.selectEntities;
export const selectWorkTableAll = workTableSelectors.selectAll;
export const selectWorkTableTotal = workTableSelectors.selectTotal;

export const selectWorkTables = createSelector(
    selectWorkTableAll,
    (
        workTables: WorkTable[],
        props: { workTableIds: number[] },
    ) => workTables.filter(workTable => props.workTableIds.includes(workTable.id)),
);

export const selectWorkTable = createSelector(
    selectWorkTableEntities,
    (
        workTableEntities: Dictionary<WorkTable>,
        props: { workTableId: number },
    ) => workTableEntities[props.workTableId] || null,
);


// Work table widgets

export const selectWorkTableWidgetsState = createSelector(
    selectLocationFeature,
    state => state.workTableWidgets,
);

const workTableWidgetSelectors = workTableWidgetAdapter.getSelectors(selectWorkTableWidgetsState);

export const selectWorkTableWidgetIds = workTableWidgetSelectors.selectIds;
export const selectWorkTableWidgetEntities = workTableWidgetSelectors.selectEntities;
export const selectWorkTableWidgetAll = workTableWidgetSelectors.selectAll;
export const selectWorkTableWidgetTotal = workTableWidgetSelectors.selectTotal;

export const selectWorkTableWidgets = createSelector(
    selectWorkTableWidgetAll,
    (
        workTableWidgets: WorkTableWidget[],
        props: { workTableIds: number[] },
    ) => workTableWidgets.filter(workTableWidget => props.workTableIds.includes(workTableWidget.workTableId)),
);

export const selectWorkTableWidget = createSelector(
    selectWorkTableWidgetEntities,
    (
        workTableWidgetEntities: Dictionary<WorkTableWidget>,
        props: { workTableWidgetId: number },
    ) => workTableWidgetEntities[props.workTableWidgetId] || null,
);
