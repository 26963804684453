import {
    createAction,
    props,
} from '@ngrx/store';

import { DemoCode, ResponseError } from '@iterra/app-lib/schemas';

export const parseDataAction = createAction(
    '[Scanner] Parse Data',
    props<{ data: string }>(),
);

export const loadDemoCodesAction = createAction(
    '[Scanner] Load Demo Codes',
);

export const loadDemoCodesSuccessAction = createAction(
    '[Scanner] Load Demo Codes Success',
    props<{ demoCodes: DemoCode[] }>(),
);

export const loadDemoCodesFailureAction = createAction(
    '[Scanner] Load Demo Codes Failure',
    props<{ error: ResponseError }>(),
);
