import {
    EntityMap,
    EntityMapOne,
    Predicate,
    Update,
} from '@ngrx/entity';
import {
    createAction,
    props,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { WorkTable } from '../../schemas/worktable.schemas';


export const setWorkTables = createAction(
    '[WorkTable] Set WorkTables',
    props<{ workTables: WorkTable[] }>(),
);

export const addWorkTable = createAction(
    '[WorkTable] Add WorkTable',
    props<{ workTable: WorkTable }>(),
);

export const setWorkTable = createAction(
    '[WorkTable] Set WorkTable',
    props<{ workTable: WorkTable }>(),
);

export const upsertWorkTable = createAction(
    '[WorkTable] Upsert WorkTable',
    props<{ workTable: WorkTable }>(),
);

export const addWorkTables = createAction(
    '[WorkTable] Add WorkTables',
    props<{ workTables: WorkTable[] }>(),
);

export const upsertWorkTables = createAction(
    '[WorkTable] Upsert WorkTables',
    props<{ workTables: WorkTable[] }>(),
);

export const updateWorkTable = createAction(
    '[WorkTable] Update WorkTable',
    props<{ update: Update<WorkTable> }>(),
);

export const updateWorkTables = createAction(
    '[WorkTable] Update WorkTables',
    props<{ updates: Update<WorkTable>[] }>(),
);

export const mapWorkTable = createAction(
    '[WorkTable] Map WorkTable',
    props<{ entityMap: EntityMapOne<WorkTable> }>(),
);

export const mapWorkTables = createAction(
    '[WorkTable] Map WorkTables',
    props<{ entityMap: EntityMap<WorkTable> }>(),
);

export const deleteWorkTable = createAction(
    '[WorkTable] Delete WorkTable',
    props<{ id: number }>(),
);

export const deleteWorkTables = createAction(
    '[WorkTable] Delete WorkTables',
    props<{ ids: number[] }>(),
);

export const deleteWorkTablesByPredicate = createAction(
    '[WorkTable] Delete WorkTables By Predicate',
    props<{ predicate: Predicate<WorkTable> }>(),
);

export const clearWorkTables = createAction(
    '[WorkTable] Clear WorkTables',
);

export const loadWorkTableAction = createAction(
    '[WorkTable] Load WorkTable',
    props<{ workTableId: number }>(),
);

export const loadWorkTableSuccessAction = createAction(
    '[WorkTable] Load WorkTable Success',
    props<{ workTable: WorkTable }>(),
);

export const loadWorkTableFailureAction = createAction(
    '[WorkTable] Load WorkTable Failure',
    props<{ error: ResponseError }>(),
);

export const setCurrentWorkTableId = createAction(
    '[WorkTable] Set Current WorkTable Id',
    props<{ workTableId: number }>(),
);

export const unsetCurrentWorkTableId = createAction(
    '[WorkTable] Unset Current WorkTable Id',
);

export const loadCurrentWorkTableWidgetsSuccessAction = createAction(
    '[WorkTable] Load Current WorkTable Widgets Success',
);
