import {
    createFeatureSelector,
    createSelector,
    select,
} from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { widgetScreenMap } from '../../app.constants';
import {
    AuthReducerState,
    DEFAULT_AUTH_FEATURE_NAME,
} from '../reducers/auth.reducer';


const selectAuth = createFeatureSelector<AuthReducerState>(DEFAULT_AUTH_FEATURE_NAME);

export const selectError = createSelector(
    selectAuth,
    state => state.error,
);

export const selectAuthorization = createSelector(
    selectAuth,
    state => state.authorization,
);

export const selectAccessToken = createSelector(
    selectAuthorization,
    authorization => authorization?.accessToken,
);

export const selectIsInit = createSelector(
    selectAuth,
    state => !!state.device,
);

export const waitAuthInit = () => pipe(
    select(selectIsInit),
    filter((isInit: boolean) => isInit),
);

export const selectIsAuth = createSelector(
    selectAccessToken,
    accessToken => !!accessToken,
);

export const selectIsLoading = createSelector(
    selectAuth,
    state => state.isLoading,
);

export const selectIsFirstRun = createSelector(
    selectAuth,
    state => state.isFirstRun,
);

export const selectDevice = createSelector(
    selectAuth,
    state => state.device,
);

export const selectUser = createSelector(
    selectAuth,
    state => state.user,
);

export const selectDeepLink = createSelector(
    selectAuth,
    state => state.deepLink,
);

export const selectIsGuest = createSelector(
    selectUser,
    user => widgetScreenMap[user?.roleId || 1] === 'guest',
);

export const selectIsPlatformAdmin = createSelector(
    selectUser,
    user => widgetScreenMap[user?.roleId || 1000] === 'admin',
);
