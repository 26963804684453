import {inject, Injectable} from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { Widget } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';
import { formatErrors } from '@iterra/app-lib/utils';

import { ALIAS_WIDGET_MAP } from '../../../environments/environment';
import { WidgetApi } from '../../services/api/widget.api';
import * as widgetActions from '../actions/widget.actions';

const logger = new Logger('WidgetEffects');

@Injectable()
export class WidgetEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);
  private widgetApi = inject(WidgetApi);

  loadWidgetsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(widgetActions.loadWidgetsAction),
      tap(({ ids }) => logger.debug('loadWidgetsEffect (ids)', ids)),
      switchMap(({ ids }) => {
        const widgetIds = ids.map(id => {
          if (typeof id === 'string') {
            return ALIAS_WIDGET_MAP[id]?.id || id;
          }
          return id;
        });

        return this.widgetApi.findWidgets({
          idIn: widgetIds.join(','),
          limit: 0,
        }).pipe(
          tap(widgets => logger.debug('loadWidgetsEffect (widgets)', widgets)),
          map((widgets: Widget[]) =>
            widgetActions.loadWidgetsSuccessAction({
              widgets,
            }),
          ),
          catchError(response => {
            logger.debug('response', response);
            return of(widgetActions.loadWidgetsFailureAction({
              error: formatErrors(response),
            }));
          }),
          first(),
        );
      }),
    ),
  );

  loadWidgetsSuccessEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(widgetActions.loadWidgetsSuccessAction),
      tap(({ widgets }) => logger.debug('loadWidgetsSuccessEffect (widgets)', widgets)),
    ),
    { dispatch: false },
  );
}
