import { inject, Injectable } from '@angular/core';
import {
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  distinct,
  tap,
} from 'rxjs/operators';

import { Capsule } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';

import * as capsuleActions from '$store/actions/capsule.actions';
import * as capsuleSelectors from '$store/selectors/capsule.selectors';

const logger = new Logger('CapsuleFacade');

@Injectable({
  providedIn: 'root',
})
export class CapsuleFacade {
  private store$ = inject(Store);

  loadCapsules(params: { capsuleIds: (number | string)[] }): void {
    this.store$.dispatch(capsuleActions.loadCapsulesAction(params));
  }

  fetchCapsule(id: number | string): Observable<Capsule | null> {
    this.loadCapsules({capsuleIds: [id]});

    return this.store$.pipe(
      select(capsuleSelectors.selectCapsule, {id}),
      distinct(capsule => !!capsule),
      tap(capsule => logger.debug('fetchCapsule (capsule)', capsule)),
    );
  }
}
