import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    EMPTY,
    of,
    timer,
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { Widget } from '@iterra/app-lib/schemas';
import { LocationApi, Logger } from '@iterra/app-lib/services';
import { formatErrors, getRandomInt } from '@iterra/app-lib/utils';

import { CapsuleFacade } from '../../facades/capsule.facade';
import { Authorization } from '../../schemas/auth.schemas';
import { Connection } from '../../schemas/connection.schemas';
import { AuthApi } from '../../services/api/auth/auth.api';
import { CapsuleApi } from '../../services/api/capsule.api';
import { ConnectionService } from '../../services/connection.service';
import * as authActions from '../actions/auth.actions';
import * as connectionActions from '../actions/connection.actions';
import * as locationActions from '../actions/location.actions';
import * as screenActions from '../actions/screen.actions';
import * as widgetActions from '../actions/widget.actions';
import * as authSelectors from '../selectors/auth.selectors';
import { waitAuthInit } from '../selectors/auth.selectors';
import * as connectionSelectors from '../selectors/connection.selectors';

const logger = new Logger('ConnectionEffects');

@Injectable()
export class ConnectionEffects {
  constructor(
    private actions$: Actions,
    private authApi: AuthApi,
    private capsuleApi: CapsuleApi,
    private connectionService: ConnectionService,
    private locationApi: LocationApi,
    private router: Router,
    private store$: Store,
    private jwtHelperService: JwtHelperService,
    private capsuleFacade: CapsuleFacade,
  ) {
  }

  connectEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(connectionActions.connectAction),
      tap(({params, isSilent}) =>
        logger.debug(
          'connectEffect (isSilent, params)',
          isSilent,
          params,
        ),
      ),
      switchMap(({params, isSilent}) => this.store$.pipe(
        select(connectionSelectors.selectConnection(params.locationId)),
        // @ts-expect-error
        take(1),
        tap((existsConnection: Connection | null) =>
          logger.debug(
            'connectEffect (existsConnection)',
            existsConnection,
          ),
        ),
        switchMap((existsConnection: Connection | null) =>
          existsConnection
            ? of(existsConnection)
            : this.connectionService.connect(params).pipe(
              tap(connection =>
                this.store$.dispatch(locationActions.loadLocationsAction({
                  locationIds: [connection.locationId],
                })),
              ),
            ),
        ),
        tap(connection => logger.debug('connectEffect (connection)', connection)),
        map((connection: Connection) => {
          return isSilent
            ? connectionActions.setCurrentConnectionAction({
              connection,
            })
            : connectionActions.connectSuccessAction({
              connection,
            });
        }),
        catchError(response => of(
          connectionActions.connectFailureAction({
            error: formatErrors(response),
          }),
        )),
      )),
    ),
  );

  refreshConnectionToken$ = createEffect(
    () => this.store$.pipe(
      select(connectionSelectors.selectCurrentConnection),
      // @ts-expect-error
      filter((connection: Connection | null) =>
        !!connection?.accessToken,
      ),
      distinctUntilChanged((
          prev: Connection,
          curr: Connection,
        ) =>
          prev?.accessToken === curr?.accessToken,
      ),
      tap((connection: Connection) =>
        logger.debug('refreshConnectionToken wait (connection)', connection),
      ),
      switchMap((connection: Connection) => {
        const token = this.jwtHelperService.decodeToken(connection.accessToken);
        const expDate = new Date(token.exp * 1000);
        const subTime = 60 * 1000 - getRandomInt(0, 15000);
        const expTime = expDate.getTime() - Date.now() - subTime;

        logger.debug('Refresh connection token after ', Math.max(0, expTime));

        return timer(Math.max(0, expTime)).pipe(
          map(() => connection),
          take(1),
        );
      }),
      switchMap((connection: Connection) => this.store$.pipe(
        select(connectionSelectors.selectCurrentConnection),
        take(1),
        filter(currentConnection => currentConnection?.accessToken === connection?.accessToken),
      )),
      tap((connection: Connection) =>
        logger.debug('refreshConnectionToken (currentConnection)', connection),
      ),
      switchMap((connection: Connection) => {
        if (!connection?.refreshToken) {
          return EMPTY;
        }

        return this.authApi.refresh(connection.refreshToken).pipe(
          map((authorization: Authorization) => {
            return connectionActions.upsertConnectionAction({
              connection: {
                ...connection,
                accessToken: authorization.accessToken,
                refreshToken: authorization.refreshToken,
              },
            });
          }),
          catchError(error => {
            logger.debug(error);

            return of(connectionActions.disconnectAction({
              locationId: connection.locationId,
            }));
          }),
        );
      }),
    ),
  );

  loadConnectionWidgetIdsEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(connectionActions.loadConnectionWidgetIdsAction),
      tap(({connection}) => logger.debug('loadConnectionWidgetIdsEffect (connection)', connection)),
      mergeMap(({connection}) => {
        if (typeof connection.widgetIds !== 'undefined') {
          return EMPTY;
        }

        this.store$.dispatch(
          connectionActions.upsertConnectionAction({
            connection: {
              ...connection,
              widgetIds: [],
            },
          }),
        );

        return this.capsuleApi.fetchWidgets(connection.locationId).pipe(
          map((widgets: Widget[]) => widgets.map(widget => widget.id)),
          map((widgetIds: number[]) => connectionActions.upsertConnectionAction({
            connection: {
              ...connection,
              widgetIds,
            },
          })),
          catchError(error => {
            logger.debug(error);

            return EMPTY;
          }),
        );
      }),
    ),
  );

  openWidgetEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(connectionActions.openWidgetAction),
      tap(({widget}) => logger.debug('openWidgetEffect (widget)', widget)),
      withLatestFrom(this.store$.pipe(
        select(connectionSelectors.selectCurrentConnection),
      )),
      tap(([{widget}, connection]) => {
        if (connection && typeof connection.widgetIds === 'undefined') {
          this.store$.dispatch(
            connectionActions.loadConnectionWidgetIdsAction({
              connection,
            }),
          );
        }

        if (connection) {
          this.store$.dispatch(widgetActions.setCurrentWidgetId({widgetId: widget.nickname}));
          this.router.navigate(
            [
              'c',
              connection.capsuleId,
              connection.locationId,
              widget.nickname,
            ],
          ).catch(reason => logger.error('Navigate failed', reason));
        }
      }),
    ),
    {dispatch: false},
  );

  resetScreenPosition$ = createEffect(
    () => this.actions$.pipe(
      ofType(connectionActions.connectSuccessAction),
      tap(connection => logger.debug('resetScreenPosition$ connection', connection)),
      tap(() => this.store$.dispatch(screenActions.resetScreenPosition())),
    ),
    {dispatch: false},
  );

  fetchCapsule$ = createEffect(
    () => this.actions$.pipe(
      ofType(connectionActions.setCurrentConnectionAction),
      tap(connection => logger.debug('fetchCapsule$ connection', connection)),
      switchMap(({connection}) => {
        return this.capsuleFacade.fetchCapsule(connection.capsuleId);
      }),
    ),
    {dispatch: false},
  );

  syncDeepLinkEffect$ = createEffect(
    () => this.store$.pipe(
      waitAuthInit(),
      switchMap(() => this.store$.select(
          connectionSelectors.selectCurrentConnection,
        ).pipe(
          distinctUntilChanged((
              prev: Connection | null,
              curr: Connection | null,
            ) =>
              prev?.locationId === curr?.locationId,
          ),
          switchMap((connection: Connection | null) =>
            this.store$.select(
              authSelectors.selectIsGuest,
            ).pipe(
              tap((isGuest: boolean) =>
                logger.debug('syncDeepLinkEffect (isGuest, connection)', isGuest, connection),
              ),
              filter((isGuest: boolean) => isGuest && connection !== null),
              map(() =>
                authActions.setDeepLinkAction({
                  // TODO: Use DeepLinkService for uri generation
                  deepLink: '/c/' + connection?.capsuleId + '/' + connection?.locationId,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );
}
