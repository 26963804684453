import { NavigationExtras } from '@angular/router';
import {
    createAction,
    props,
} from '@ngrx/store';


export const goToRedirectUriAction = createAction(
    '[Router] Go To Redirect Uri',
    props<{ commands?: any[], extras?: NavigationExtras }>(),
);

export const goWithCurrentRedirectUriAction = createAction(
    '[Router] Go With Current Redirect Uri',
    props<{ commands: any[], extras?: NavigationExtras }>(),
);

export const goFavoriteLocationListAction = createAction(
    '[Router] Go Favorite Location List Action',
);

export const goAuthAction = createAction(
    '[Router] Go Auth Action',
);
