import {
    createAction,
    props,
} from '@ngrx/store';
import { About } from '../../schemas/about.schemas';


export const upsertAbouts = createAction(
    '[About] Upsert Abouts',
    props<{ abouts: About[] }>(),
);

export const loadAboutsSuccessAction = createAction(
    '[About] Load Abouts Success',
    props<{ abouts: About[] }>(),
);
