import {inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {
  map,
  take,
  tap,
} from 'rxjs/operators';

import {
  FavoriteLocation,
  FavoriteLocationFindParams,
  Location,
  LocationsFindParams,
  LocationWidget,
  LocationWidgetFindParams,
  LocationWidgetUpdate,
  RecommendationLocationsParams,
  RootLocationTreeParams,
} from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';
import { convertKeysToCamel, convertKeysToSnake } from '@iterra/app-lib/utils';

import { ApiService } from './api.service';

const logger = new Logger('LocationApi');

@Injectable({
  providedIn: 'root',
})
export class LocationApi {
  private apiService = inject(ApiService);

  findLocations(params: LocationsFindParams): Observable<Location[]> {
    return this.apiService.get<any>(
      '/capsule/location',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(locations => logger.debug('findLocations (locations)', locations)),
      take(1),
    );
  }

  fetchLocationAdmin(params: LocationsFindParams): Observable<Location[]> {
    return this.apiService.get<any>(
      '/capsule/location/admin',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(locations => logger.debug('fetchLocationAdmin (locations)', locations)),
      take(1),
    );
  }

  fetchRecommendationLocation(params: RecommendationLocationsParams): Observable<Location[]> {
    return this.apiService.get<any>(
      '/recommendation/location',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(locations => logger.debug('fetchRecommendationLocation (locations)', locations)),
      take(1),
    );
  }

  fetchRootLocationTree(params: RootLocationTreeParams): Observable<Location[]> {
    return this.apiService.get<any>(
      '/capsule/location/tree',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(locations => logger.debug('fetchRootLocationTree (locations)', locations)),
      take(1),
    );
  }

  fetchFavoriteLocations(params: FavoriteLocationFindParams):
    Observable<FavoriteLocation[]> {
    return this.apiService.get<any>(
      '/capsule/favorite',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(favoriteLocations => logger.debug('fetchFavoriteLocations (favoriteLocations)', favoriteLocations)),
      take(1),
    );
  }

  toggleFavoriteLocation(params: FavoriteLocation): Observable<boolean> {
    return this.apiService.post<any>(
      '/capsule/favorite',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(favoriteLocation => logger.debug('toggleFavoriteLocation (favoriteLocation)', favoriteLocation)),
      take(1),
    );
  }

  findLocationWidgets(params: LocationWidgetFindParams): Observable<LocationWidget[]> {
    return this.apiService.get<LocationWidget[]>(
      '/capsule/widget-config',
      convertKeysToSnake(params),
    ).pipe(
      tap(locationWidgets => logger.debug('findLocationWidgets (locationWidgets)', locationWidgets)),
    );
  }

  updateLocationWidgetById(id: number, data: LocationWidgetUpdate): Observable<LocationWidget> {
    return this.apiService.patch<LocationWidget>(
      '/capsule/widget-config/' + id,
      convertKeysToSnake(data),
    ).pipe(
      tap(locationWidget => logger.debug('updateLocationWidgetById (locationWidget)', locationWidget)),
    );
  }
}
