import {
    createAction,
    props,
} from '@ngrx/store';

import { ConnectionParams, ResponseError } from '@iterra/app-lib/schemas';

import { Connection } from '../../schemas/connection.schemas';
import { Widget } from '../../schemas/widget.schemas';

export const connectAction = createAction(
    '[Connection] Connect',
    props<{
        params: ConnectionParams,
        isSilent?: boolean
    }>(),
);

export const connectSuccessAction = createAction(
    '[Connection] Connect Success',
    props<{ connection: Connection }>(),
);

export const connectFailureAction = createAction(
    '[Connection] Connect Failure',
    props<{ error: ResponseError }>(),
);

export const disconnectAction = createAction(
    '[Connection] Disconnect',
    props<{ locationId: number }>(),
);

export const setCurrentConnectionAction = createAction(
    '[Connection] Set Current Connection',
    props<{ connection: Connection }>(),
);

export const upsertConnectionAction = createAction(
    '[Connection] Upsert Connection',
    props<{ connection: Connection }>(),
);

export const loadConnectionWidgetIdsAction = createAction(
    '[Connection] Load Connection Widget Ids',
    props<{ connection: Connection }>(),
);

export const openWidgetAction = createAction(
    '[Connection] Open Widget',
    props<{ widget: Widget }>(),
);
