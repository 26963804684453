import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

import { Logger } from '@iterra/app-lib/services';

import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'iTerra';

  constructor(
    private router: Router,
    private appService: AppService,
    private updateService: UpdateService,
  ) {
    // Subscribe to router events to handle page transition
    this.router.events.subscribe(this.navigationInterceptor.bind(this));

    // Disable animations and transitions in IE10 to increase performance
    if (typeof (document as any).documentMode === 'number' && (document as any).documentMode < 11) {
      const style = document.createElement('style');
      style.textContent = `
        * {
          -ms-animation: none !important;
          animation: none !important;
          -ms-transition: none !important;
          transition: none !important;
        }`;
      document.head.appendChild(style);
    }

    this.updateService.listen();
  }

  public ngOnInit(): void {
    if (environment.production) {
      Logger.enableProductionMode();
    }
  }

  private navigationInterceptor(e: RouterEvent): void {
    if (e instanceof NavigationStart) {
      // Set loading state
      document.body.classList.add('app-loading');
    }

    if (e instanceof NavigationEnd) {
      // Scroll to top of the page
      this.appService.scrollTop(0, 0);
    }

    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      // Remove loading state
      document.body.classList.remove('app-loading');
    }
  }
}
