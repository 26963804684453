import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
} from '@ngrx/entity';
import {
  createReducer,
  on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import * as aboutActions from '../actions/about.actions';
import { About } from '../../schemas/about.schemas';

export const DEFAULT_ABOUT_FEATURE_NAME = 'about';

export interface AboutReducerState extends EntityState<About> {
  error: ResponseError | null;
}

export function selectAboutId(a: About): number {
  return a.id;
}

export function sortByTitle(a: About, b: About): number {
  return a.title.localeCompare(b.title);
}

export const aboutAdapter: EntityAdapter<About> = createEntityAdapter<About>({
  selectId: selectAboutId,
  sortComparer: sortByTitle,
});

export const initialState: AboutReducerState = aboutAdapter.getInitialState({
  error: null,
});

export const aboutReducer = createReducer(
  initialState,
  on(
    aboutActions.upsertAbouts,
    (state, {abouts}) => aboutAdapter.upsertMany(abouts, state),
  ),
);
