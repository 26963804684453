import {
    createAction,
    props,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import {
    Authorization,
    PhoneCode,
    PhoneCredentials,
    TelegramCredentials,
    User,
} from '../../schemas/auth.schemas';

export const setInitAction = createAction(
    '[Auth] Init',
    props<{
        device: string | null,
        deepLink: string | null,
        authorization: Authorization | null
    }>(),
);

export const signUpAction = createAction(
    '[Auth] Sign Up',
);

export const signUpSuccessAction = createAction(
    '[Auth] Sign Up Success',
    props<{ authorization: Authorization }>(),
);

export const signUpFailureAction = createAction(
    '[Auth] Sign Up Failure',
    props<{ error: ResponseError; }>(),
);


export const signUpByPhoneCodeAction = createAction(
    '[Auth] Sign Up By Phone Code',
    props<{ phoneCode: PhoneCode }>(),
);

export const signUpByPhoneCodeSuccessAction = createAction(
    '[Auth] Sign Up By Phone Code Success',
    props<{ authorization: Authorization }>(),
);

export const signUpByPhoneCodeFailureAction = createAction(
    '[Auth] Sign Up By Phone Code Failure',
    props<{ error: ResponseError; }>(),
);


export const signInAction = createAction(
    '[Auth] Sign In',
);

export const signInSuccessAction = createAction(
    '[Auth] Sign In Success',
    props<{ authorization: Authorization }>(),
);

export const signInFailureAction = createAction(
    '[Auth] Sign In Failure',
    props<{ error: ResponseError; }>(),
);


export const signInByPhoneCodeAction = createAction(
    '[Auth] Sign In By Phone Code',
    props<{ phoneCode: PhoneCode }>(),
);

export const signInByPhoneCodeSuccessAction = createAction(
    '[Auth] Sign In By Phone Code Success',
    props<{ authorization: Authorization }>(),
);

export const signInByPhoneCodeFailureAction = createAction(
    '[Auth] Sign In By Phone Code Failure',
    props<{ error: ResponseError; }>(),
);


export const signInByPhoneCredentialsAction = createAction(
    '[Auth] Sign In By Phone Credentials',
    props<{ credentials: PhoneCredentials }>(),
);

export const signInByPhoneCredentialsSuccessAction = createAction(
    '[Auth] Sign In By Phone Credentials Success',
    props<{ authorization: Authorization }>(),
);

export const signInByPhoneCredentialsFailureAction = createAction(
    '[Auth] Sign In By Phone Credentials Failure',
    props<{ error: ResponseError; }>(),
);


export const signInByPasswordResetCodeAction = createAction(
    '[Auth] Sign In By Password Reset Code',
    props<{ phoneCode: PhoneCode }>(),
);

export const signInByPasswordResetCodeSuccessAction = createAction(
    '[Auth] Sign In By Password Reset Code Success',
    props<{ authorization: Authorization }>(),
);

export const signInByPasswordResetCodeFailureAction = createAction(
    '[Auth] Sign In By Password Reset Code Failure',
    props<{ error: ResponseError; }>(),
);

export const signInByDeviceAction = createAction(
    '[Auth] Sign In By Device',
);

export const signInByDeviceSuccessAction = createAction(
    '[Auth] Sign In By Device Success',
    props<{ authorization: Authorization }>(),
);

export const signInByDeviceFailureAction = createAction(
    '[Auth] Sign In By Device Failure',
    props<{ error: ResponseError; }>(),
);

export const signOutAction = createAction(
    '[Auth] Sign Out',
);

export const signOutSuccessAction = createAction(
    '[Auth] Sign Out Success',
);

export const signOutFailureAction = createAction(
    '[Auth] Sign Out Failure',
);

export const setDeviceAction = createAction(
    '[Auth] Set Device',
    props<{ device: string | null; }>(),
);

export const setAuthorizationAction = createAction(
    '[Auth] Set Authorization',
    props<{ authorization: Authorization }>(),
);

export const signInByTelegramAction = createAction(
    '[Auth] Sign In By Telegram',
    props<{ credentials: TelegramCredentials }>(),
);

export const signInByTelegramSuccessAction = createAction(
    '[Auth] Sign In By Telegram Success',
    props<{ authorization: Authorization }>(),
);

export const signInByTelegramFailureAction = createAction(
    '[Auth] Sign In By Telegram Failure',
    props<{ error: ResponseError; }>(),
);

export const setUserAction = createAction(
    '[Auth] Set User',
    props<{ user: User | null }>(),
);

export const setDeepLinkAction = createAction(
    '[Auth] Set Deep Link',
    props<{ deepLink: string | null; }>(),
);
