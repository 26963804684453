import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  first,
  map,
} from 'rxjs/operators';

import { convertKeysToSnake } from '@iterra/app-lib/utils';

import {
  Authorization,
  PhoneCode,
  PhoneCredentials,
} from '$schemas/auth.schemas';
import { ApiService } from '../api.service';
import { toAuthorization } from '../converter';

@Injectable({
  providedIn: 'root',
})
export class AuthPhoneApi {

  constructor(
    private apiService: ApiService,
  ) { }

  authorize(credentials: PhoneCredentials): Observable<Authorization> {
    return this.apiService.post<any>(
      '/authentication/phone/authorize',
      credentials,
    ).pipe(map(toAuthorization));
  }

  sendCode(phone: string): Observable<null> {
    return this.apiService.post<null>(
      '/authentication/phone/code',
      {phone},
    );
  }

  authorizeByPhoneCode(phoneCode: PhoneCode): Observable<Authorization> {
    return this.apiService.post<any>(
      '/authentication/phone/code/authorize',
      phoneCode,
    ).pipe(map(toAuthorization));
  }

  validatePhoneCode(phoneCode: PhoneCode): Observable<null> {
    return this.apiService.post<null>(
      '/authentication/phone/code/validate',
      convertKeysToSnake(phoneCode),
    ).pipe(first());
  }

  bindPhoneByCode(phoneCode: PhoneCode): Observable<null> {
    return this.apiService.post<null>(
      '/authentication/phone/code/bind',
      phoneCode,
    );
  }

  unbindPhoneByCode(phoneCode: PhoneCode): Observable<null> {
    return this.apiService.post<null>(
      '/authentication/phone/code/unbind',
      phoneCode,
    );
  }

}
