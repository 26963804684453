import {
    createReducer,
    on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { Connection } from '../../schemas/connection.schemas';
import * as authActions from '../actions/auth.actions';
import * as connectionActions from '../actions/connection.actions';

export const DEFAULT_CONNECTION_FEATURE_NAME = 'connection';

export interface ConnectionReducerState {
    connections: Connection[];
    currentLocationId?: number;
    currentCapsuleId?: number;
    isConnecting: boolean;
    error: ResponseError | null;
}

const initialState: ConnectionReducerState = {
    connections: [],
    isConnecting: false,
    error: null,
};

export const connectionReducer = createReducer(
    initialState,
    on(
        connectionActions.connectAction,
        state => ({
            ...state,
            isConnecting: true,
            error: null,
        }),
    ),
    on(
        connectionActions.connectSuccessAction,
        (state, {connection}) => ({
            ...state,
            connections: [
                ...state.connections.filter(item => item.locationId !== connection.locationId),
                connection,
            ],
            currentLocationId: connection.locationId,
            currentCapsuleId: connection.capsuleId,
            isConnecting: false,
            error: null,
        }),
    ),
    on(
        connectionActions.connectFailureAction,
        (state, {error}) => ({
            ...state,
            isConnecting: false,
            error,
        }),
    ),
    on(
        connectionActions.disconnectAction,
        (state, {locationId}) => {
            const connections = state.connections.filter(
                connection => connection.locationId !== locationId,
            );

            const currentLocationId = (state.currentLocationId === locationId)
                ? (connections.length
                    ? connections[connections.length - 1].locationId
                    : undefined)
                : state.currentLocationId;

            const currentCapsuleId = (state.currentLocationId === locationId)
                ? (connections.length
                    ? connections[connections.length - 1].capsuleId
                    : undefined)
                : state.currentCapsuleId;

            return {
                ...state,
                connections,
                currentLocationId,
                currentCapsuleId,
            };
        },
    ),
    on(
        connectionActions.setCurrentConnectionAction,
        (state, {connection}) => ({
            ...state,
            connections: [
                ...state.connections.filter(item => item.locationId !== connection.locationId),
                connection,
            ],
            currentLocationId: connection.locationId,
            currentCapsuleId: connection.capsuleId,
        }),
    ),
    on(
        connectionActions.upsertConnectionAction,
        (state, {connection}) => ({
            ...state,
            connections: [
                ...state.connections.filter(item => item.locationId !== connection.locationId),
                connection,
            ],
        }),
    ),
    on(
        authActions.signOutSuccessAction,
        () => ({
            ...initialState,
        }),
    ),
);
