import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaFormComponent } from './captcha-form/captcha-form.component';
import { CaptchaFigureComponent } from './captcha-figure/captcha-figure.component';


const components = [
    CaptchaFormComponent,
    CaptchaFigureComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ...components,
    ],
})
export class CaptchaModule {}
