import {inject, Injectable} from '@angular/core';
import {
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ResponseError } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';

import {
  PhoneCode,
  PhoneCredentials,
  User,
} from '../schemas/auth.schemas';
import * as authActions from '../store/actions/auth.actions';
import * as passwordActions from '../store/actions/password.actions';
import * as authSelectors from '../store/selectors/auth.selectors';
import { waitAuthInit } from '../store/selectors/auth.selectors';

const logger = new Logger('AuthFacade');

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  private store$ = inject(Store);

  get isAuth$(): Observable<boolean> {
    return this.store$.pipe(
      waitAuthInit(),
      switchMap(() => this.store$.pipe(
        select(authSelectors.selectIsAuth),
      )),
    );
  }

  get isGuest$(): Observable<boolean> {
    return this.store$.select(authSelectors.selectIsGuest);
  }

  get isPlatformAdmin$(): Observable<boolean> {
    return this.store$.select(authSelectors.selectIsPlatformAdmin);
  }

  get user$(): Observable<User | null> {
    return this.store$.pipe(
      select(authSelectors.selectUser),
    );
  }

  get isLoading$(): Observable<boolean> {
    return this.store$
      .pipe(
        select(authSelectors.selectIsLoading),
      );
  }

  get error$(): Observable<ResponseError | null> {
    return this.store$.pipe(
      select(authSelectors.selectError),
    );
  }

  signInAsGuest(): void {
    this.store$.dispatch(authActions.signInByDeviceAction());
  }

  signUpByPhoneCode(phoneCode: PhoneCode): void {
    this.store$.dispatch(authActions.signUpByPhoneCodeAction({phoneCode}));
  }

  signInByPhoneCode(phoneCode: PhoneCode): void {
    this.store$.dispatch(authActions.signInByPhoneCodeAction({phoneCode}));
  }

  signInByPhoneCredentials(credentials: PhoneCredentials): void {
    this.store$.dispatch(authActions.signInByPhoneCredentialsAction({credentials}));
  }

  signInByPasswordResetCode(phoneCode: PhoneCode): void {
    this.store$.dispatch(authActions.signInByPasswordResetCodeAction({phoneCode}));
  }

  resetPassword(password: string): void {
    this.store$.dispatch(passwordActions.resetAction({password}));
  }

  createPassword(password: string): void {
    this.store$.dispatch(passwordActions.passwordAction({password}));
  }

  signOut(): void {
    this.store$.dispatch(authActions.signOutAction());
  }

}
