<div class="auth-guest">
  <div class="auth-guest__info">
    <it-icon
        name="it-user"
        width="22"
        height="22"
        color="var(--color-main)"
    />
    <div class="auth-guest__description">Вы вошли как гость</div>
  </div>

  <it-button
      buttonStyle="primary"
      (clicked)="onClickButton()"
  >
    Войти
  </it-button>
</div>
