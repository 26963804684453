<div class="captcha">
    <div>
        <div class="captcha__title">
            <div class="captcha__logo"></div>
            <div>iTerra Captcha</div>
        </div>
        <div class="captcha__text">
            Подтвердите своё присутствие в локации
        </div>
        <div class="captcha__figures">
            <app-captcha-figure *ngFor="let figure of figures"
                                (click)="onClickFigure(figure)"
                                [isSelected]="isSelected(figure)"
                                [figure]="figure"
                                class="column">
            </app-captcha-figure>
        </div>
        <div class="captcha__text captcha__text--secondary">
            Выберите те же фигуры, что присутствуют на экране
        </div>
        <button class="btn btn-primary iterra-wide" (click)="refreshCaptcha()">Обновить</button>
    </div>
</div>
