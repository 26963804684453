<nav class="footer">
  <div class="background-filter"></div>
  <div class="footer__container">
    <a
        class="footer__link"
        (click)="goToLink($event, '/favorites/locations')"
    >
      <it-icon
          width="36"
          height="36"
          name="it-bookmark"
          [color]="activeRouteUrl.startsWith('/favorites/') ? 'var(--color-main)' : 'var(--color-secondary-light)'"
      />
    </a>
    <a
        class="footer__link"
        (click)="goToLink($event, '/search')"
    >
      <it-icon
          width="36"
          height="36"
          name="it-search"
          [color]="activeRouteUrl === '/search' ? 'var(--color-main)' : 'var(--color-secondary-light)'"
      />
    </a>
    <a
        class="footer__link"
        (click)="goToLink($event, locationWidgetsLink)"
    >
      <img
          *ngIf="currentLocationIcon"
          class="footer__icon"
          [src]="currentLocationIcon"
      >
      <it-icon
          *ngIf="!currentLocationIcon"
          width="36"
          height="36"
          name="it-home"
          [color]="activeRouteUrl.startsWith('/c/') ? 'var(--color-main)' : 'var(--color-secondary-light)'"
      />
    </a>
  </div>
</nav>
