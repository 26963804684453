import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
  Title,
} from '@angular/platform-browser';
import 'hammerjs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
  basketIcon,
  errorMessageIcon,
  magnifierIcon,
  moderationMessageIcon,
  nextTrackIcon,
  pausedIcon,
  prevTrackIcon,
  shareIcon,
  successMessageIcon,
  trackIcon,
  warningMessageIcon,
} from '@iterra/app-lib/it-system-icons';
import { ItIconModule } from '@iterra/app-lib/it-icons';
import { ItMessagesModule } from '@iterra/app-lib/it-messages';

import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import {
  backIcon,
  bookmarkIcon,
  connectIcon,
  downArrowIcon,
  fullBookmarkIcon,
  homeIcon,
  iTerraIcon,
  gearIcon,
  lightStarIcon,
  rightArrowIcon,
  historyIcon,
  plusIcon,
  searchIcon,
  structureIcon,
  verticalSettingsIcon,
  userIcon,
} from '$constants/icons'
import { DeactivateGuard } from './guards/deactivate.guard';
import { WidgetConnectionGuard } from './guards/widget-connection.guard';
import { AuthInterceptorProvider } from './interceptors/auth.interceptor';
import { DeviceHashInterceptorProvider } from './interceptors/device-hash.interceptor';
import { ErrorHandlerInterceptorProvider } from './interceptors/error-handler.interceptor';
import { LayoutModule } from './layout/layout.module';
import { AppRoutingModule } from './routing/routing.module';
import {
  effects,
  metaReducers,
  reducers,
} from './store';
import { AppStoreModule } from './store/store.module';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
    pinch: { enable: true },
  };

  buildHammer(element: HTMLElement)
  {
    let options = {};

    // @ts-expect-error
    if (element.attributes['dataOptions'])
    {
      try
      {
        // @ts-expect-error
        options = JSON.parse(element.attributes['dataOptions'].nodeValue);
      }
      catch (err)
      {
        console.error('An error occurred when attempting to parse Hammer.js options: ', err);
      }
    }

    const mc = new Hammer(element, options);


    // retain support for angular overrides object
    for (const eventName of Object.keys(this.overrides))
    {
      mc.get(eventName).set(this.overrides[eventName]);
    }

    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    RouterModule.forRoot([], {
      paramsInheritanceStrategy: 'always',
    }),
    EffectsModule.forRoot(effects),
    ItIconModule.forRoot(
      {
        icons: [
          backIcon,
          basketIcon,
          bookmarkIcon,
          connectIcon,
          downArrowIcon,
          errorMessageIcon,
          fullBookmarkIcon,
          iTerraIcon,
          gearIcon,
          lightStarIcon,
          magnifierIcon,
          moderationMessageIcon,
          nextTrackIcon,
          homeIcon,
          rightArrowIcon,
          historyIcon,
          pausedIcon,
          plusIcon,
          prevTrackIcon,
          searchIcon,
          shareIcon,
          structureIcon,
          successMessageIcon,
          trackIcon,
          verticalSettingsIcon,
          warningMessageIcon,
          userIcon,
        ],
        size: 24,
      }
    ),
    ItMessagesModule.forRoot({
      errors: {},
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ToastrModule.forRoot({
      tapToDismiss: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgbModule,
    AppStoreModule,
    AppRoutingModule,
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production || true,
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000',
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: request => request as any,
      },
    }),
  ],
  providers: [
    AppService,
    Title,
    DeactivateGuard,
    ErrorHandlerInterceptorProvider,
    DeviceHashInterceptorProvider,
    AuthInterceptorProvider,
    { provide: 'environment', useValue: environment },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {}
