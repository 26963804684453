import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';
import {
    createReducer,
    on,
} from '@ngrx/store';

import { ResponseError } from '@iterra/app-lib/schemas';

import { WorkTable } from '../../schemas/worktable.schemas';
import * as workTableActions from '../actions/work-table.actions';

export const DEFAULT_WORK_TABLE_FEATURE_NAME = 'workTable';

export interface WorkTableReducerState extends EntityState<WorkTable> {
    isLoading: boolean;
    error: ResponseError | null;
    currentWorkTableId: number | null;
}

export function selectWorkTableId(workTable: WorkTable): number {
    return workTable.id;
}

export function sortByTitle(a: WorkTable, b: WorkTable): number {
    return a.title.localeCompare(b.title);
}

export const workTableAdapter: EntityAdapter<WorkTable> = createEntityAdapter<WorkTable>({
    selectId: selectWorkTableId,
    sortComparer: sortByTitle,
});

export const initialState: WorkTableReducerState = workTableAdapter.getInitialState({
    isLoading: false,
    error: null,
    currentWorkTableId: null,
});

export const workTableReducer = createReducer(
    initialState,
    on(
        workTableActions.addWorkTable,
        (state, {workTable}) => workTableAdapter.addOne(workTable, state),
    ),
    on(
        workTableActions.setWorkTable,
        (state, {workTable}) => workTableAdapter.setOne(workTable, state),
    ),
    on(
        workTableActions.upsertWorkTable,
        (state, {workTable}) => workTableAdapter.upsertOne(workTable, state),
    ),
    on(
        workTableActions.addWorkTables,
        (state, {workTables}) => workTableAdapter.addMany(workTables, state),
    ),
    on(
        workTableActions.upsertWorkTables,
        (state, {workTables}) => workTableAdapter.upsertMany(workTables, state),
    ),
    on(
        workTableActions.updateWorkTable,
        (state, {update}) => workTableAdapter.updateOne(update, state),
    ),
    on(
        workTableActions.updateWorkTables,
        (state, {updates}) => workTableAdapter.updateMany(updates, state),
    ),
    on(
        workTableActions.mapWorkTable,
        (state, {entityMap}) => workTableAdapter.mapOne(entityMap, state),
    ),
    on(
        workTableActions.mapWorkTables,
        (state, {entityMap}) => workTableAdapter.map(entityMap, state),
    ),
    on(
        workTableActions.deleteWorkTable,
        (state, {id}) => workTableAdapter.removeOne(id, state),
    ),
    on(
        workTableActions.deleteWorkTables,
        (state, {ids}) => workTableAdapter.removeMany(ids, state),
    ),
    on(
        workTableActions.deleteWorkTablesByPredicate,
        (state, {predicate}) => workTableAdapter.removeMany(predicate, state),
    ),
    on(
        workTableActions.setWorkTables,
        (state, {workTables}) => workTableAdapter.setAll(workTables, state),
    ),
    on(
        workTableActions.clearWorkTables,
        state => workTableAdapter.removeAll(state),
    ),
    on(
        workTableActions.loadWorkTableAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        workTableActions.loadWorkTableSuccessAction,
        (state, {workTable}) => workTableAdapter.setOne(
            workTable,
            {
                ...state,
                isLoading: false,
            },
        ),
    ),
    on(
        workTableActions.loadWorkTableFailureAction,
        (state, {error}) => ({
            ...state,
            isLoading: false,
            error,
        }),
    ),
    on(
        workTableActions.setCurrentWorkTableId,
        (state, {workTableId}) => ({
            ...state,
            currentWorkTableId: workTableId,
        }),
    ),
    on(
        workTableActions.unsetCurrentWorkTableId,
        state => ({
            ...state,
            currentWorkTableId: null,
        }),
    ),
);
