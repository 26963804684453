import {
    createAction,
    props,
} from '@ngrx/store';


export const saveScreenPosition = createAction(
    '[Screen] Save Screen',
    props<{ position: number | null }>(),
);

export const resetScreenPosition = createAction(
    '[Screen] Reset Screen',
);
