import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { TelegramAutoAuthGuard } from '../guards/telegram-auto-auth.guard';
import { LayoutBlankComponent } from '../layout/layout-blank/layout-blank.component';
import { LayoutIterraComponent } from '../layout/layout-iterra/layout-iterra.component';
import { CaptchaModule } from '../modules/captcha/captcha.module';
import { TelegramBotComponent } from '../modules/telegram/telegram-bot/telegram-bot.component';
import { TelegramPostComponent } from '../modules/telegram/telegram-post/telegram-post.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/search',
  },
  {
    path: 'search',
    canActivate: [AuthGuard],
    component: LayoutIterraComponent,
    loadChildren: () => import('./search-routing.module')
      .then(m => m.SearchRoutingModule),
  },
  {
    path: 'auth',
    component: LayoutBlankComponent,
    loadChildren: () => import('./auth-routing.module')
      .then(m => m.AuthRoutingModule),
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    component: LayoutIterraComponent,
    loadChildren: () => import('./account-routing.module')
      .then(m => m.AccountRoutingModule),
  },
  {
    path: 'favorites',
    canActivate: [AuthGuard],
    component: LayoutIterraComponent,
    loadChildren: () => import('./favorites-routing.module')
      .then(m => m.FavoritesRoutingModule),
  },
  {
    path: 'capsule',
    component: LayoutIterraComponent,
    loadChildren: () => import('./capsule-routing.module')
      .then(m => m.CapsuleRoutingModule),
  },
  {
    path: 'c',
    canActivate: [AuthGuard],
    loadChildren: () => import('./capsules-routing.module')
      .then(m => m.CapsulesRoutingModule),
  },
  // {
  //     path: 'connect',
  //     component: LayoutIterraComponent,
  //     loadChildren: () => import('./connect-routing.module')
  //         .then(m => m.ConnectRoutingModule),
  // },
  {
    path: 'telegram-post',
    component: LayoutBlankComponent,
    children: [
      {
        path: '',
        component: TelegramPostComponent,
      },
    ],
  },
  {
    path: 'tests',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tests-routing.module')
      .then(m => m.TestsRoutingModule),
  },
  {
    path: 'telegram-post',
    component: LayoutBlankComponent,
    children: [
      {
        path: '',
        component: TelegramPostComponent,
      },
    ],
  },
  {
    path: 'telegram-bot',
    component: LayoutBlankComponent,
    children: [
      {
        path: '',
        component: TelegramBotComponent,
      },
    ],
  },
];

const rootRoutes: Routes = [
  {
    path: '',
    canActivateChild: [
      TelegramAutoAuthGuard,
    ],
    children: [
      ...routes,
    ],
  },
  // 404 Not Found page
  // {
  //   path: '**',
  //   component: NotFoundComponent,
  // },
];

@NgModule({
  imports: [
    CaptchaModule,
    RouterModule.forChild(rootRoutes),
  ],
})
export class AppRoutingModule {}
