import {inject, Injectable} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  tap,
} from 'rxjs/operators';

import { Capsule } from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';
import { formatErrors } from '@iterra/app-lib/utils';

import { CapsuleApi } from '../../services/api/capsule.api';
import * as capsuleActions from '../actions/capsule.actions';

const logger = new Logger('CapsuleEffects');

@Injectable()
export class CapsuleEffects {
  private actions$ = inject(Actions);
  private capsuleApi = inject(CapsuleApi);
  private store$ = inject(Store);

  loadCapsulesSuccessEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(capsuleActions.loadCapsulesSuccessAction),
      tap(({capsules}) => logger.debug('loadCapsulesSuccessEffect$ (capsules)', capsules)),
      map(({capsules}) => capsuleActions.upsertCapsules({capsules})),
    ),
  );

  loadCapsulesEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(capsuleActions.loadCapsulesAction),
      tap(({capsuleIds}) => logger.debug('loadCapsulesEffect$ (capsuleIds)', capsuleIds)),
      filter(({capsuleIds}) => !!capsuleIds?.length),
      concatMap((params: { capsuleIds?: (number | string)[] }) => {
        return this.capsuleApi.fetchCapsules({
          idIn: params.capsuleIds?.join(','),
        }).pipe(
          tap(capsules => logger.debug('loadCapsulesEffect$ (capsules)', capsules)),
          tap((capsules: Capsule[]) => {
            if (capsules.length) {
              this.store$.dispatch(capsuleActions.loadCapsulesSuccessAction({
                capsules: [...capsules],
              }));
            }
          }),
          catchError(response => {
            logger.debug('loadCapsulesEffect$ (response)', response);
            this.store$.dispatch(capsuleActions.loadCapsulesFailureAction({
              error: formatErrors(response),
            }));
            return of(false);
          }),
        );
      }),
    ),
    {dispatch: false},
  );
}
