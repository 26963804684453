import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    distinctUntilKeyChanged,
    of,
    timer,
} from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import { convertKeysToCamel, formatErrors, getRandomInt } from '@iterra/app-lib/utils';

import {
    Authorization,
    Device,
    User,
} from '../../schemas/auth.schemas';
import { AuthPhoneApi } from '../../services/api/auth/auth-phone.api';
import { AuthApi } from '../../services/api/auth/auth.api';
import { DeviceAuthApi } from '../../services/api/auth/device-auth.api';
import * as authActions from '../actions/auth.actions';
import * as passwordActions from '../actions/password.actions';
import * as authSelectors from '../selectors/auth.selectors';

const logger = new Logger('AuthEffects');

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authApi: AuthApi,
        private authPhoneApi: AuthPhoneApi,
        private deviceAuthApi: DeviceAuthApi,
        private store$: Store,
        private jwtHelperService: JwtHelperService,
    ) {}

    createDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.setInitAction,
                authActions.setDeviceAction,
            ),
            distinctUntilKeyChanged('device'),
            filter(({device}) => device === null),
            tap(() => logger.debug('Create Device Effect')),
            switchMap(() => this.deviceAuthApi.create().pipe(
                map((device: Device) =>
                    authActions.setDeviceAction({device: device.hash}),
                ),
            )),
            first(),
        ),
    );

    signUpEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signUpByPhoneCodeAction,
            ),
            map(() => authActions.signUpAction()),
            first(),
        ),
    );

    signUpSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signUpByPhoneCodeSuccessAction,
            ),
            map(({authorization}) => authActions.signUpSuccessAction({authorization})),
            first(),
        ),
    );

    signUpFailureEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signUpByPhoneCodeFailureAction,
            ),
            map(({error}) => authActions.signUpFailureAction({error})),
            first(),
        ),
    );

    signInEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInByDeviceAction,
                authActions.signInByPhoneCodeAction,
                authActions.signInByPhoneCredentialsAction,
                authActions.signInByPasswordResetCodeAction,
                authActions.signInByTelegramAction,
            ),
            map(() => authActions.signInAction()),
            first(),
        ),
    );

    signInSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInByDeviceSuccessAction,
                authActions.signInByPhoneCodeSuccessAction,
                authActions.signInByPhoneCredentialsSuccessAction,
                authActions.signInByPasswordResetCodeSuccessAction,
                authActions.signInByTelegramSuccessAction,
                authActions.signUpSuccessAction,
            ),
            map(({authorization}) => authActions.signInSuccessAction({authorization})),
        ),
    );

    setAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInSuccessAction),
            map(({authorization}) => authActions.setAuthorizationAction({authorization})),
        ),
    );

    signInFailureEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInByDeviceFailureAction,
                authActions.signInByPhoneCodeFailureAction,
                authActions.signInByPhoneCredentialsFailureAction,
                authActions.signInByPasswordResetCodeFailureAction,
                authActions.signInByTelegramFailureAction,
                passwordActions.resetFailureAction,
            ),
            map(({error}) => authActions.signInFailureAction({error})),
            first(),
        ),
    );

    signUpByPhoneCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signUpByPhoneCodeAction),
            switchMap(({phoneCode}) =>
                this.authPhoneApi.authorizeByPhoneCode(phoneCode).pipe(
                    map((authorization: Authorization) =>
                        authActions.signUpByPhoneCodeSuccessAction({authorization})),
                    catchError(response => of(authActions.signUpByPhoneCodeFailureAction({
                        error: formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signInByPhoneCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCodeAction),
            switchMap(({phoneCode}) =>
                this.authPhoneApi.authorizeByPhoneCode(phoneCode).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPhoneCodeSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPhoneCodeFailureAction({
                        error: formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signInByPhoneCredentialsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCredentialsAction),
            switchMap(({credentials}) =>
                this.authPhoneApi.authorize(credentials).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPhoneCredentialsSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPhoneCredentialsFailureAction({
                        error: formatErrors(response),
                    }))),
                ),
            ),
        ),
    );

    signInByTelegramEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByTelegramAction),
            switchMap(({credentials}) =>
                this.authApi.authorizeByTelegram(credentials).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByTelegramSuccessAction({authorization}),
                    ),
                    catchError(response => credentials?.asGuestIfFail
                        ? of(authActions.signInByDeviceAction())
                        : of(authActions.signInByTelegramFailureAction({
                            error: formatErrors(response),
                        })),
                    ),
                ),
            ),
            first(),
        ),
    );

    signInByDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByDeviceAction),
            switchMap(() =>
                this.deviceAuthApi.authorize().pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByDeviceSuccessAction({
                            authorization,
                        }),
                    ),
                    catchError(response =>
                        of(authActions.signInByDeviceFailureAction({
                            error: formatErrors(response),
                        })),
                    ),
                    first(),
                ),
            ),
        ),
    );

    signInByPasswordResetCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPasswordResetCodeAction),
            switchMap(({phoneCode}) =>
                this.authPhoneApi.authorizeByPhoneCode(phoneCode).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPasswordResetCodeSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPasswordResetCodeFailureAction({
                        error: formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signOutEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signOutAction),
            map(() => authActions.signOutSuccessAction()),
            catchError(() => of(authActions.signOutFailureAction())),
        ),
    );

    refreshToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.setAuthorizationAction),
            switchMap(({authorization}) => {
                const token = this.jwtHelperService.decodeToken(authorization.accessToken);
                const expDate = new Date(token.exp * 1000);
                const subTime = 60 * 1000 - getRandomInt(0, 15000);
                const expTime = expDate.getTime() - Date.now() - subTime;

                logger.debug('Refresh token after ', Math.max(0, expTime));

                return timer(Math.max(0, expTime)).pipe(
                    map(() => authorization),
                    take(1),
                );
            }),
            tap((authorization: Authorization) =>
                logger.debug('refreshToken', authorization.refreshToken),
            ),
            switchMap((authorization: Authorization) =>
                this.authApi.refresh(authorization.refreshToken).pipe(
                    map((newAuthorization: Authorization) =>
                        authActions.setAuthorizationAction({
                            authorization: newAuthorization,
                        }),
                    ),
                ),
            ),
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if ([401, 422, 403].includes(error.status)) {
                        return of(authActions.signOutAction());
                    }
                }

                throw error;
            }),
            first(),
        ),
    );

    clearDeepLinkEffect$ = createEffect(
        () => this.store$.pipe(
            select(authSelectors.selectIsGuest),
            filter((isGuest: boolean) => !isGuest),
            switchMap(() => this.actions$.pipe(
                ofType(authActions.signOutSuccessAction),
                tap(() => logger.debug('clearDeepLinkEffect')),
                map(() => authActions.setDeepLinkAction({
                    deepLink: null,
                })),
                first(),
            )),
        ),
    );

    setUserEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.setAuthorizationAction),
            map(({authorization}) => {
                const token = this.jwtHelperService.decodeToken(authorization.accessToken);
                const ctx = convertKeysToCamel(token.ctx);
                const user: User = {
                    id: ctx.userId,
                    roleId: ctx.roleId,
                };
                return authActions.setUserAction({user});
            }),
            catchError(() => {
                return of(authActions.setUserAction({user: null}));
            }),
        ),
    );
}
