import {
  ApplicationRef,
  inject,
  Injectable,
} from '@angular/core';
import {
  SwUpdate,
  VersionEvent,
} from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import {
  concat,
  filter,
  interval,
  tap,
} from 'rxjs';
import { first } from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

const logger = new Logger('UpdateService');

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  private appRef = inject(ApplicationRef);
  private toastrService = inject(ToastrService);
  private updates = inject(SwUpdate);

  listen(): void {
    console.log('this.updates.isEnabled', this.updates.isEnabled);
    if (this.updates.isEnabled) {
      this.logUpdate();
      // this.notifyUpdate();
      this.checkForUpdate();
      // this.handleUnrecoverable();
    }
  }

  private logUpdate(): void {
    console.log('logUpdate');
    this.updates.versionUpdates.pipe(
      tap((event) => console.log('versionUpdates event', event)),
      filter((event) => event.type === 'VERSION_READY'),
      tap(() => this.notifyUser(() => this.reloadApp()))
    ).subscribe((event: VersionEvent) => {
      console.log('available: type', event.type);
      console.log('event', event);
    });
  }

  private checkForUpdate(): void {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    console.log('checkForUpdate');
    const isStableApp$ = this.appRef.isStable.pipe(
      tap((isStable)=> console.log('isStable', isStable)),
      first(isStable => isStable),
    );
    const everyOneHour$ = interval(60 * 1000);
    const everyOneHourOnceAppIsStable$ = concat(isStableApp$, everyOneHour$);

    everyOneHour$.subscribe(() => {
      console.log('everyOneHour$');
      this.updates.checkForUpdate();
    });
  }

  private notifyUser(onTap: () => void): void {
    console.log('notifyUser');
    const toast = this.toastrService.warning(
      'Кликните, чтобы обновить',
      'Нужно обновиться!',
      {
        disableTimeOut: true,
      },
    );
    toast.onTap.subscribe(onTap);
  }

  private reloadApp(): void {
    document.location.reload();
    logger.info('reloadApp: The app is updating right now');
  }
}
