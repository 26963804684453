import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';
import { DemoCode } from '@iterra/app-lib/schemas';
import { formatErrors } from '@iterra/app-lib/utils';

import { DemoService } from '../../services/demo.service';
import * as scannerActions from '../actions/scanner.actions';


const logger = new Logger('ScannerEffects');

@Injectable()
export class ScannerEffects {

    constructor(
        private actions$: Actions,
        private demoService: DemoService,
        private router: Router,
    ) { }

    loadDemoCodesEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(scannerActions.loadDemoCodesAction),
            switchMap(() => this.demoService.fetchDemoCodes().pipe(
                map((demoCodes: DemoCode[]) =>
                    scannerActions.loadDemoCodesSuccessAction({
                        demoCodes,
                    }),
                ),
                catchError(response => of(
                    scannerActions.loadDemoCodesFailureAction({
                        error: formatErrors(response),
                    }),
                )),
            )),
        ),
    );

    parseDataEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(scannerActions.parseDataAction),
            filter(({data}) => (typeof data === 'string') && /^https?/.test(data)),
            tap(({data}) => {
                const url = new URL(data);

                if (url.pathname.indexOf('/c/') === 0) {
                    const redirectUri = url.pathname + url.search + url.hash;

                    this.router.navigateByUrl(redirectUri)
                        .catch(reason => logger.error('Navigate failed', reason));
                }
            }),
        ),
        {dispatch: false},
    );

}
