import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';

import { BaseComponent } from '@iterra/app-lib/directives';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-layout-blank',
    templateUrl: './layout-blank.component.html',
    styleUrls: ['./layout-blank.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutBlankComponent {}
