import {
    EntityMap,
    EntityMapOne,
    Predicate,
    Update,
} from '@ngrx/entity';
import {
    createAction,
    props,
} from '@ngrx/store';

import { LocationGeo, LocationTag, ResponseError, Tag, WorkTable, WorkTableWidget } from '@iterra/app-lib/schemas';

import { Location } from '../../schemas/location.schemas';

export const setLocations = createAction(
    '[Location] Set Locations',
    props<{ locations: Location[] }>(),
);

export const addLocation = createAction(
    '[Location] Add Location',
    props<{ location: Location }>(),
);

export const setLocation = createAction(
    '[Location] Set Location',
    props<{ location: Location }>(),
);

export const upsertLocation = createAction(
    '[Location] Upsert Location',
    props<{ location: Location }>(),
);

export const addLocations = createAction(
    '[Location] Add Locations',
    props<{ locations: Location[] }>(),
);

export const upsertLocations = createAction(
    '[Location] Upsert Locations',
    props<{ locations: Location[] }>(),
);

export const updateLocation = createAction(
    '[Location] Update Location',
    props<{ update: Update<Location> }>(),
);

export const updateLocations = createAction(
    '[Location] Update Locations',
    props<{ updates: Update<Location>[] }>(),
);

export const mapLocation = createAction(
    '[Location] Map Location',
    props<{ entityMap: EntityMapOne<Location> }>(),
);

export const mapLocations = createAction(
    '[Location] Map Locations',
    props<{ entityMap: EntityMap<Location> }>(),
);

export const deleteLocation = createAction(
    '[Location] Delete Location',
    props<{ id: number }>(),
);

export const deleteLocations = createAction(
    '[Location] Delete Locations',
    props<{ ids: number[] }>(),
);

export const deleteLocationsByPredicate = createAction(
    '[Location] Delete Locations By Predicate',
    props<{ predicate: Predicate<Location> }>(),
);

export const clearLocations = createAction(
    '[Location] Clear Locations',
);

export const loadRootLocationTreeAction = createAction(
    '[Location] Load Tree Root Location',
    props<{ rootLocationId: number }>(),
);

export const loadLocationsAction = createAction(
    '[Location] Load Locations',
    props<{ locationIds?: number[] }>(),
);

export const loadLocationsSuccessAction = createAction(
    '[Location] Load Locations Success',
    props<{ locations: Location[] }>(),
);

export const loadLocationsFailureAction = createAction(
    '[Location] Load Locations Failure',
    props<{ error: ResponseError }>(),
);


// Location tags

export const loadLocationsTagsAction = createAction(
    '[Location] Load Locations Tags',
    props<{ locationIds: number[] }>(),
);

export const loadLocationsTagsSuccessAction = createAction(
    '[Location] Load Locations Tags Success',
    props<{ locationTags: LocationTag[] }>(),
);

export const loadLocationsTagsFailureAction = createAction(
    '[Location] Load Locations Tags Failure',
    props<{ error: ResponseError }>(),
);


// Tags

export const loadTagsAction = createAction(
    '[Location] Load Tags',
    props<{ tagIds: number[] }>(),
);

export const loadTagsSuccessAction = createAction(
    '[Location] Load Tags Success',
    props<{ tags: Tag[] }>(),
);

export const loadTagsFailureAction = createAction(
    '[Location] Load Tags Failure',
    props<{ error: ResponseError }>(),
);


// Location geos

export const loadLocationsGeosAction = createAction(
    '[Location] Load Locations Geos',
    props<{ locationIds: number[] }>(),
);

export const loadLocationsGeosSuccessAction = createAction(
    '[Location] Load Locations Geos Success',
    props<{ locationGeos: LocationGeo[] }>(),
);

export const loadLocationsGeosFailureAction = createAction(
    '[Location] Load Locations Geos Failure',
    props<{ error: ResponseError }>(),
);


// Work tables

export const loadWorkTablesAction = createAction(
    '[Location] Load Work Tables',
    props<{ workTableIds: number[] }>(),
);

export const loadWorkTablesSuccessAction = createAction(
    '[Location] Load Work Tables Success',
    props<{ workTables: WorkTable[] }>(),
);

export const loadWorkTablesFailureAction = createAction(
    '[Location] Load Work Tables Failure',
    props<{ error: ResponseError }>(),
);


// Work table widgets

export const loadWorkTableWidgetsAction = createAction(
    '[Location] Load Work Table Widgets',
    props<{ workTableIds: number[] }>(),
);

export const loadWorkTableWidgetsSuccessAction = createAction(
    '[Location] Load Work Table Widgets Success',
    props<{ workTableWidgets: WorkTableWidget[] }>(),
);

export const loadWorkTableWidgetsFailureAction = createAction(
    '[Location] Load Work Table Widgets Failure',
    props<{ error: ResponseError }>(),
);
