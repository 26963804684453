import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

import { ConnectionFacade } from '$facades/connection.facade';
import { YandexMetrikaService } from '$services/yandex-metrika.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-layout-iterra-navbar',
  templateUrl: './layout-iterra-navbar.component.html',
  styleUrls: ['./layout-iterra-navbar.component.scss'],
  host: {
    class: 'navbar',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutIterraNavbarComponent {
  @Input() public title: string | null | undefined = '';
  @Output() public toggleSidenav = new EventEmitter<void>();

  @HostBinding('class.layout-navbar') hostClassMain = true;

  private connectionFacade = inject(ConnectionFacade);
  private yandexMetrikaService = inject(YandexMetrikaService);

  protected isConnected$: Observable<boolean> = this.connectionFacade.isConnected$;

  protected reachGoal(): void {
    this.yandexMetrikaService.onReachGoal(environment.yandexCounterId, 'navbar-click-connections');
  }

  protected onToggleSidenav(): void {
    this.toggleSidenav.emit();
  }
}
