import {inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {
  map,
  take,
  tap,
} from 'rxjs/operators';

import {
  Captcha,
  CaptchaValidateParams,
  Connection,
  ConnectionLog,
  ConnectionParams,
  HistoryParams,
  WidgetConnection,
  WidgetConnectionParams,
} from '@iterra/app-lib/schemas';
import { Logger } from '@iterra/app-lib/services';
import { convertKeysToCamel, convertKeysToSnake } from '@iterra/app-lib/utils';

import { ApiService } from './api.service';

const logger = new Logger('ConnectionApi');

@Injectable({
  providedIn: 'root',
})
export class ConnectionApi {
  private apiService = inject(ApiService);

  public create(params: ConnectionParams): Observable<Connection> {
    return this.apiService.post<any>(
      '/connection',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(connection => logger.debug('create connection', connection)),
      take(1),
    );
  }

  public createWidgetConnection(params: WidgetConnectionParams): Observable<WidgetConnection> {
    return this.apiService.post<any>(
      '/connection/widget',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(connection => logger.debug('create widget connection', connection)),
      take(1),
    );
  }

  public fetchHistory(params: HistoryParams): Observable<ConnectionLog[] | null> {
    return this.apiService.get<any>(
      '/connection/history',
      convertKeysToSnake(params),
    ).pipe(
      map(convertKeysToCamel),
      tap(history => logger.debug('fetch history connection', history)),
      take(1),
    );
  }

  public fetchCaptcha(): Observable<Captcha> {
    return this.apiService.get<any>(
      '/connection/captcha',
      {},
    ).pipe(
      map(convertKeysToCamel),
      tap(captcha => logger.debug('fetch captcha', captcha)),
      take(1),
    );
  }

  public validateCaptcha(params: CaptchaValidateParams): Observable<Connection> {
    return this.apiService.post<any>(
      '/connection/captcha/authorize',
      convertKeysToSnake(params),
      [422],
    ).pipe(
      map(convertKeysToCamel),
      tap(connection => logger.debug('create captcha connection', connection)),
      take(1),
    );
  }
}
