import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { BaseComponent } from '@iterra/app-lib/directives';
import { Logger } from '@iterra/app-lib/services';

import { AuthFacade } from '$facades/auth.facade';
import { ConnectionFacade } from '$facades/connection.facade';
import { ImageSize } from '$schemas/image.schemas';
import { Location } from '$schemas/location.schemas';
import { ImageService } from '$services/image.service';
import { environment } from '../../../environments/environment';
import { YandexMetrikaService } from '$services/yandex-metrika.service';

const logger = new Logger('LayoutIterraComponent');

@Component({
  selector: 'app-layout-iterra',
  templateUrl: './layout-iterra.component.html',
  styleUrls: ['./layout-iterra.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutIterraComponent extends BaseComponent implements OnInit {
  @ViewChild(MatDrawer, { static: false }) private drawer!: MatDrawer;

  private authFacade = inject(AuthFacade);
  private connectionFacade = inject(ConnectionFacade);
  private imageService = inject(ImageService);
  private router = inject(Router);
  private yandexMetrikaService = inject(YandexMetrikaService);

  protected backgroundImageStyle: string | null = null;
  protected currentLocation: Location | null = null;
  protected isGuest = false;

  protected get isConnectCapsule(): boolean {
    return this.router.url.startsWith('/c/');
  }

  public ngOnInit(): void {
    this.authFacade.isGuest$
      .pipe(this.takeUntilDestroy())
      .subscribe({
        next: (isGuest) => this.isGuest = isGuest,
      });

    this.connectionFacade.currentLocation$
      .pipe(this.takeUntilDestroy())
      .subscribe({
        next: (location) => {
          this.currentLocation = location;
          this.setBackgroundImage();
        },
      });
  }

  protected toggleSidenav(): void {
    if (this.isGuest) return;

    this.drawer.toggle();
  }

  protected signOut(): void {
    this.reachGoal('logout');
    this.authFacade.signOut();
  }

  protected goToLink(event: Event, path: string): void {
    event.stopPropagation();

    this.reachGoal(path);
    this.router.navigate([path]);
  }

  protected reachGoal(path: string): void {
    switch (path) {
      case '/account/settings':
        this.yandexMetrikaService.onReachGoal(environment.yandexCounterId, 'click-account-settings');
        break;
      case 'logout':
        this.yandexMetrikaService.onReachGoal(environment.yandexCounterId, 'click-logout');
        break;
    }
  }

  protected goToAuth(): void {
    this.authFacade.signOut();
  }

  private setBackgroundImage(): void {
    const backgroundSize: ImageSize = this.imageService.getImageSize(window.innerWidth, window.innerHeight);
    const backgroundImage: string | undefined = this.currentLocation?.manifest?.background?.image;

    this.backgroundImageStyle = backgroundImage && backgroundSize
      ? `url(${backgroundImage}?thumb=${backgroundSize.width}x${backgroundSize.height})`
      : null;
  }
}
