import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { BaseComponent } from '@iterra/app-lib/directives';
import { Logger } from '@iterra/app-lib/services';

import { AuthFacade } from '$facades/auth.facade';
import { ConnectionFacade } from '$facades/connection.facade';
import { YandexMetrikaService } from '$services/yandex-metrika.service';
import { environment } from '../../../environments/environment';

const logger = new Logger('LayoutIterraFooterComponent');

@Component({
  selector: 'app-layout-iterra-footer',
  templateUrl: './layout-iterra-footer.component.html',
  styleUrls: ['./layout-iterra-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutIterraFooterComponent extends BaseComponent implements OnInit {
  @HostBinding('class.layout-footer') hostClassMain = true;

  private authFacade = inject(AuthFacade);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private connectionFacade = inject(ConnectionFacade);
  private router = inject(Router);
  private toastrService = inject(ToastrService);
  private yandexMetrikaService = inject(YandexMetrikaService);

  protected activeRouteUrl = '';
  protected currentLocationIcon?: string;
  protected locationWidgetsLink: string = '/';
  protected isGuest = false;

  public ngOnInit(): void {
    this.activeRouteUrl = this.router.url;
    
    this.connectionFacade.currentConnection$
      .pipe(this.takeUntilDestroy())
      .subscribe({
        next: (connection) => {
          if (connection) {
            this.locationWidgetsLink = `/c/${connection.capsuleId}/${connection.locationId}`;
          }
        },
      });

    this.authFacade.isGuest$
      .pipe(this.takeUntilDestroy())
      .subscribe({
        next: (isGuest) => {
          this.isGuest = isGuest;
          this.changeDetectorRef.markForCheck();
        },
      });

    this.connectionFacade
      .currentLocation$
      .pipe(this.takeUntilDestroy())
      .subscribe({
        next: (location) => {
          this.currentLocationIcon = location?.icon;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  protected goToLink(event: Event, path: string): void {
    event.stopPropagation();

    if (!this.isGuest) {
      this.router.navigate([path]);
    } else {
      this.toastrService.warning('Необходима авторизация');
    }

    this.reachGoal(path);
  }

  private reachGoal(path: string): void {
    switch (path) {
      case '/search':
        this.yandexMetrikaService.onReachGoal(environment.yandexCounterId, 'click-search');
        break;
      case '/c/':
        this.yandexMetrikaService.onReachGoal(environment.yandexCounterId, 'click-screen');
        break;
    }
  }
}
