import {inject, Injectable} from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import {
    fromEvent,
    of,
} from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

import { Authorization } from '../../schemas/auth.schemas';
import * as appActions from '../actions/app.actions';
import * as authActions from '../actions/auth.actions';
import * as authSelectors from '../selectors/auth.selectors';

const logger = new Logger('LocalStorageEffects');

@Injectable()
export class LocalStorageEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store);

  saveAuthorizationEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.setAuthorizationAction),
      tap(({authorization}) => {
        const authorizationJson = localStorage.getItem('authorization');
        const storedAuthorization: Authorization | null = authorizationJson
          ? JSON.parse(authorizationJson)
          : null;

        if (storedAuthorization?.accessToken !== authorization.accessToken) {
          logger.debug('saveAuthorizationEffect (authorization)', authorization);

          localStorage.setItem('authorization', JSON.stringify(authorization));
        }
      }),
    ),
    {dispatch: false},
  );

  initAuthEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(appActions.initAction),
      switchMap(() => {
        const inputParams = new URLSearchParams(window.location.search);
        const isTelegramAuth = (inputParams.get('auth') === 'telegram');
        const device = localStorage.getItem('device');
        const deepLink = !isTelegramAuth
          ? localStorage.getItem('deepLink')
          : null;
        const authorizationJson = localStorage.getItem('authorization');
        const authorization = authorizationJson && !isTelegramAuth
          ? JSON.parse(authorizationJson)
          : null;

        logger.debug('initAuthEffect (device)', device);
        logger.debug('initAuthEffect (deepLink)', deepLink);
        logger.debug('initAuthEffect (authorization)', authorization);

        const actions: TypedAction<string>[] = [
          authActions.setInitAction({
            device,
            deepLink,
            authorization,
          }),
        ];

        if (authorization) {
          actions.push(authActions.setAuthorizationAction({
            authorization,
          }));
        }

        return actions;
      }),
      catchError(() => of(authActions.signOutAction())),
    ),
  );

  listenAuthorizationEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(appActions.initAction),
      switchMap(() => fromEvent<StorageEvent>(window, 'storage')),
      filter((event: StorageEvent) => event.key === 'authorization'),
      tap((event: StorageEvent) => logger.debug('listenAuthorizationEffect (event)', event)),
      withLatestFrom(
        this.store$.select(authSelectors.selectAuthorization),
      ),
      map(([event, currentValue]) => {
        const newValue: Authorization | null = (event.newValue !== null)
          ? JSON.parse(event.newValue)
          : null;

        if (newValue?.accessToken === currentValue?.accessToken) {
          return appActions.NoopAction();
        }

        logger.debug('listenAuthorizationEffect (newValue)', newValue);

        if (newValue === null) {
          return authActions.signOutAction();
        }

        return authActions.setAuthorizationAction({
          authorization: newValue,
        });
      }),
    ),
  );

  signOutEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.signOutAction),
      tap(() => {
        localStorage.removeItem('authorization');
      }),
    ),
    {dispatch: false},
  );

  listenDeviceEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(appActions.initAction),
      switchMap(() => fromEvent<StorageEvent>(window, 'storage')),
      filter((event: StorageEvent) => event.key === 'device'),
      tap((event: StorageEvent) => logger.debug('listenDeviceEffect (event)', event)),
      withLatestFrom(
        this.store$.select(authSelectors.selectDevice),
      ),
      tap(([event, currentDevice]) => {
        if (event.newValue !== currentDevice) {
          this.store$.dispatch(
            authActions.setDeviceAction({
              device: event.newValue,
            }),
          );
        }
      }),
    ),
    {dispatch: false},
  );

  saveDeviceEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.setDeviceAction),
      tap(({device}) => {
        if (!device) {
          return localStorage.removeItem('device');
        }

        const storedDevice = localStorage.getItem('device');

        if (storedDevice !== device) {
          logger.debug('saveDeviceEffect (device)', device);

          localStorage.setItem('device', device);
        }
      }),
    ),
    {dispatch: false},
  );

  saveDeepLinkEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.setDeepLinkAction),
      tap(({deepLink}) => {
        if (!deepLink) {
          return localStorage.removeItem('deepLink');
        }

        const storedDeepLink = localStorage.getItem('deepLink');

        if (storedDeepLink !== deepLink) {
          logger.debug('saveDeepLinkEffect (deepLink)', deepLink);

          localStorage.setItem('deepLink', deepLink);
        }

        return;
      }),
    ),
    {dispatch: false},
  );

  listenDeepLinkEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(appActions.initAction),
      switchMap(() => fromEvent<StorageEvent>(window, 'storage')),
      filter((event: StorageEvent) => event.key === 'deepLink'),
      tap((event: StorageEvent) => logger.debug('listenDeepLinkEffect (event)', event)),
      withLatestFrom(
        this.store$.select(authSelectors.selectDeepLink),
      ),
      tap(([event, currentDeepLink]) => {
        if (event.newValue !== currentDeepLink) {
          this.store$.dispatch(
            authActions.setDeepLinkAction({
              deepLink: event.newValue,
            }),
          );
        }
      }),
    ),
    {dispatch: false},
  );
}
