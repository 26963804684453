import {
    createReducer,
    on,
} from '@ngrx/store';

import * as screenActions from '../actions/screen.actions';


export const DEFAULT_SCREEN_FEATURE_NAME = 'screen';

export interface ScreenReducerState {
    position: number | null;
}

const initialState: ScreenReducerState = {
    position: null,
};

export const screenReducer = createReducer(
    initialState,
    on(
        screenActions.saveScreenPosition,
        (state, {position}) => ({
            ...state,
            position,
        }),
    ),
    on(
        screenActions.resetScreenPosition,
        (state) => ({
            ...state,
            position: null,
        }),
    ),
);
