import {
    DEFAULT_ROUTER_FEATURENAME,
    routerReducer,
    RouterReducerState,
} from '@ngrx/router-store';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
} from '@ngrx/store';

import { environment } from '../../environments/environment';
import { AboutEffects } from './effects/about.effects';
import { AuthEffects } from './effects/auth.effects';
import { CapsuleEffects } from './effects/capsule.effects';
import { ConnectionEffects } from './effects/connection.effects';
import { FavoriteEffects } from './effects/favorite.effects';
import { HistoryEffects } from './effects/history.effects';
import { LayoutEffects } from './effects/layout.effects';
import { LocalStorageEffects } from './effects/local-storage.effects';
import { LocationEffects } from './effects/location.effects';
import { PasswordEffects } from './effects/password.effects';
import { PhoneEffects } from './effects/phone.effects';
import { RouterEffects } from './effects/router.effects';
import { ScannerEffects } from './effects/scanner.effects';
import { ScreenEffects } from './effects/screen.effects';
import { WidgetEffects } from './effects/widget.effects';
import { WorkTableEffects } from './effects/work-table.effects';
import {
    aboutReducer,
    AboutReducerState,
    DEFAULT_ABOUT_FEATURE_NAME,
} from './reducers/about.reducer';
import {
    authReducer,
    AuthReducerState,
    DEFAULT_AUTH_FEATURE_NAME,
} from './reducers/auth.reducer';
import {
    capsuleReducer,
    CapsuleReducerState,
    DEFAULT_CAPSULE_FEATURE_NAME,
} from './reducers/capsule.reducer';
import {
    connectionReducer,
    ConnectionReducerState,
    DEFAULT_CONNECTION_FEATURE_NAME,
} from './reducers/connection.reducer';
import {
    DEFAULT_FAVORITE_FEATURE_NAME,
    favoriteReducer,
    FavoriteReducerState,
} from './reducers/favorite.reducer';
import {
    DEFAULT_LAYOUT_FEATURE_NAME,
    layoutReducer,
    LayoutReducerState,
} from './reducers/layout.reducer';
import {
    DEFAULT_LOCATION_FEATURE_NAME,
    locationReducer,
    LocationReducerState,
} from './reducers/location.reducer';
import {
    DEFAULT_PHONE_FEATURE_NAME,
    phoneReducer,
    PhoneReducerState,
} from './reducers/phone.reducer';
import {
    DEFAULT_SCANNER_FEATURE_NAME,
    scannerReducer,
    ScannerReducerState,
} from './reducers/scanner.reducer';
import {
    DEFAULT_SCREEN_FEATURE_NAME,
    screenReducer,
    ScreenReducerState,
} from './reducers/screen.reducer';
import {
    DEFAULT_WIDGET_FEATURE_NAME,
    widgetReducer,
    WidgetReducerState,
} from './reducers/widget.reducer';
import {
    DEFAULT_WORK_TABLE_FEATURE_NAME,
    workTableReducer,
    WorkTableReducerState,
} from './reducers/work-table.reducer';


export interface State {
    [DEFAULT_AUTH_FEATURE_NAME]: AuthReducerState;
    [DEFAULT_PHONE_FEATURE_NAME]: PhoneReducerState;
    [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
    [DEFAULT_WORK_TABLE_FEATURE_NAME]: WorkTableReducerState;
    [DEFAULT_SCANNER_FEATURE_NAME]: ScannerReducerState;
    [DEFAULT_WIDGET_FEATURE_NAME]: WidgetReducerState;
    [DEFAULT_CONNECTION_FEATURE_NAME]: ConnectionReducerState;
    [DEFAULT_LAYOUT_FEATURE_NAME]: LayoutReducerState;
    [DEFAULT_LOCATION_FEATURE_NAME]: LocationReducerState;
    [DEFAULT_FAVORITE_FEATURE_NAME]: FavoriteReducerState;
    [DEFAULT_SCREEN_FEATURE_NAME]: ScreenReducerState;
    [DEFAULT_ABOUT_FEATURE_NAME]: AboutReducerState;
    [DEFAULT_CAPSULE_FEATURE_NAME]: CapsuleReducerState;
}

export const reducers: ActionReducerMap<State> = {
    [DEFAULT_AUTH_FEATURE_NAME]: authReducer,
    [DEFAULT_PHONE_FEATURE_NAME]: phoneReducer,
    [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
    [DEFAULT_WORK_TABLE_FEATURE_NAME]: workTableReducer,
    [DEFAULT_SCANNER_FEATURE_NAME]: scannerReducer,
    [DEFAULT_WIDGET_FEATURE_NAME]: widgetReducer,
    [DEFAULT_CONNECTION_FEATURE_NAME]: connectionReducer,
    [DEFAULT_LAYOUT_FEATURE_NAME]: layoutReducer,
    [DEFAULT_LOCATION_FEATURE_NAME]: locationReducer,
    [DEFAULT_FAVORITE_FEATURE_NAME]: favoriteReducer,
    [DEFAULT_SCREEN_FEATURE_NAME]: screenReducer,
    [DEFAULT_ABOUT_FEATURE_NAME]: aboutReducer,
    [DEFAULT_CAPSULE_FEATURE_NAME]: capsuleReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [debug]
    : [];

export const effects = [
    AuthEffects,
    PasswordEffects,
    PhoneEffects,
    RouterEffects,
    LocalStorageEffects,
    WorkTableEffects,
    ScannerEffects,
    WidgetEffects,
    ConnectionEffects,
    LayoutEffects,
    LocationEffects,
    FavoriteEffects,
    HistoryEffects,
    ScreenEffects,
    AboutEffects,
    CapsuleEffects,
];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => reducer(state, action);
}
