import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Logger } from '@iterra/app-lib/services';

import * as authActions from '../store/actions/auth.actions';
import * as routerActions from '../store/actions/router.actions';
import * as authSelectors from '../store/selectors/auth.selectors';
import { waitAuthInit } from '../store/selectors/auth.selectors';

const logger = new Logger('AuthGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  private readonly router = inject(Router);
  private readonly store$ = inject(Store);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuth().pipe(
      tap((isAuth: boolean) => logger.debug('canActivate (isAuth, state.url)', isAuth, state.url)),
      withLatestFrom(
        this.store$.select(authSelectors.selectIsFirstRun),
      ),
      map(([isAuth, isFirstRun]) => {
        logger.debug('canActivate (isAuth)', isAuth);
        logger.debug('canActivate (isFirstRun)', isFirstRun);

        if (isAuth) {
          return true;
        }

        this.store$.dispatch(authActions.setDeepLinkAction({
          deepLink: state.url,
        }));

        if (isFirstRun) {
          this.store$.dispatch(authActions.signInByDeviceAction());
        } else {
          this.store$.dispatch(routerActions.goAuthAction());
        }

        return false;
      }),
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuth();
  }

  private isAuth(): Observable<boolean> {
    return this.store$.pipe(
      waitAuthInit(),
      switchMap(() => this.store$.select(
        authSelectors.selectIsAuth,
      )),
      take(1),
    );
  }

}
